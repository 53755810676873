import axios from 'axios';
import { toastr } from 'react-redux-toastr';

// Keep track of authentication errors
let authFailureCount = 0;
const AUTH_MAX_FAILURES = 3;

// Rate limiting management
const RATE_LIMIT_RETRY_MAX = 3;
const rateLimitedRequests = new Map();
const pendingRetries = new Map();

// Calculate backoff time with jitter to prevent thundering herd
const calculateBackoff = (retryCount) => {
  const baseDelay = 1000; // Start with 1 second
  const maxDelay = 10000; // Max 10 seconds
  const exponentialDelay = Math.min(maxDelay, baseDelay * Math.pow(2, retryCount));
  // Add jitter (random value between 0 and 1000ms)
  return exponentialDelay + Math.floor(Math.random() * 1000);
};

// Create a GraphQL request interceptor for logging purposes
axios.interceptors.request.use(function (config) {
  // Check if we're in a rate limit state for this URL
  const requestKey = config.url;
  if (rateLimitedRequests.has(requestKey)) {
    const rateLimitInfo = rateLimitedRequests.get(requestKey);
    const now = Date.now();
    
    // If we're still in the rate limit window, reject the request
    if (now < rateLimitInfo.resetTime) {
      const remainingTime = Math.ceil((rateLimitInfo.resetTime - now) / 1000);
      console.log(`Rate limited for ${remainingTime}s more: ${requestKey}`);
      
      // For critical requests, we'll queue them for retry
      const isGraphQL = config.url?.includes('/graphql') || config.url?.includes('/v1/graphql');
      const isCritical = isGraphQL || config.url?.includes('/api/workspaces') || config.url?.includes('/api/user');
      
      if (isCritical && rateLimitInfo.retryCount < RATE_LIMIT_RETRY_MAX) {
        // Queue for retry with exponential backoff
        const retryDelay = calculateBackoff(rateLimitInfo.retryCount);
        console.log(`Queuing retry in ${retryDelay}ms for: ${requestKey}`);
        
        // Prevent duplicate retries
        if (!pendingRetries.has(requestKey)) {
          const timeoutId = setTimeout(() => {
            console.log(`Retrying rate-limited request: ${requestKey}`);
            pendingRetries.delete(requestKey);
            rateLimitedRequests.delete(requestKey);
            
            // Make a fresh request with the same config
            axios(config);
          }, retryDelay);
          
          pendingRetries.set(requestKey, timeoutId);
        }
      }
      
      return Promise.reject(new Error(`Rate limited: Please try again in ${remainingTime} seconds`));
    } else {
      // Rate limit expired, remove it
      rateLimitedRequests.delete(requestKey);
    }
  }
  
  // Check if this is a GraphQL request
  if (config.url?.includes('/graphql') || config.url?.includes('/v1/graphql')) {
    if (config.data && typeof config.data === 'string') {
      try {
        // Try to parse the GraphQL data
        const graphQLData = JSON.parse(config.data);
        
        // Log folder-related operations to help with debugging
        if (
          graphQLData.query?.includes('insert_folders') || 
          graphQLData.query?.includes('folders') ||
          graphQLData.query?.includes('CREATE_FOLDER_QUERY')
        ) {
          console.log('📁 GraphQL folder operation:', { 
            operation: 'request',
            variables: graphQLData.variables,
            query: graphQLData.query?.slice(0, 100) + '...' // Truncate for readability
          });
        }
      } catch (err) {
        // Silent fail for non-JSON data
      }
    }
  }
  
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Response interceptor to handle errors and authentication
axios.interceptors.response.use(
  function (response) {
    // Reset auth failure count on successful responses
    authFailureCount = 0;
    
    // Check if there are GraphQL errors in the response
    if (
      response.data && 
      response.data.errors && 
      Array.isArray(response.data.errors) && 
      response.data.errors.length > 0
    ) {
      // Log GraphQL errors for debugging
      console.error('📁 GraphQL operation errors:', response.data.errors);
      
      // Check for folder-related errors
      const isFolderOperation = 
        response.config?.data?.includes('folders') || 
        response.config?.data?.includes('CREATE_FOLDER_QUERY');
      
      if (isFolderOperation) {
        console.error('📁 GraphQL folder operation failed:', {
          url: response.config.url,
          data: typeof response.config.data === 'string' 
            ? JSON.parse(response.config.data) 
            : response.config.data,
          errors: response.data.errors
        });
      }
    }
    
    return response;
  },
  function (error) {
    const requestKey = error.config?.url;
    
    // Check if this is an AWS throttling error
    const isAwsThrottling = 
      error?.response?.data?.Code === 'Throttling' || 
      error?.response?.data?.message?.includes('Rate exceeded') ||
      error?.message?.includes('Throttling') ||
      error?.message?.includes('Rate exceeded');
    
    // Handle rate limiting (429 Too Many Requests or AWS Throttling)
    if (isAwsThrottling || (error.response && error.response.status === 429)) {
      console.log(`⚠️ Rate limited${isAwsThrottling ? ' (AWS)' : ' (429)'} for: ${requestKey}`);
      
      // Dispatch a global event so components can react
      window.dispatchEvent(new CustomEvent('rate-limit-detected', { 
        detail: { 
          status: error.response?.status || 429, 
          url: requestKey,
          isApi: true,
          isAws: isAwsThrottling
        }
      }));
      
      // Calculate when we can retry based on headers or default backoff
      let retryDelay = 5000; // Default to 5 seconds for AWS throttling
      let retryAfter = null;
      
      // For standard 429 errors, check for retry-after header
      if (error.response && error.response.status === 429) {
        retryAfter = error.response.headers['retry-after'];
        retryDelay = retryAfter ? (parseInt(retryAfter, 10) * 1000) : 30000; // Default to 30 seconds for 429
      }
      
      const resetTime = Date.now() + retryDelay;
        
      // Track this rate-limited request
      const currentEntry = rateLimitedRequests.get(requestKey) || { retryCount: 0 };
      rateLimitedRequests.set(requestKey, {
        resetTime,
        retryCount: currentEntry.retryCount + 1
      });
      
      // For workspace/folder data, show a user-friendly message
      const isWorkspaceData = 
        requestKey?.includes('/api/workspaces') || 
        requestKey?.includes('/graphql') || 
        requestKey?.includes('/api/user') ||
        requestKey?.includes('/api/self/subscription');
        
      if (isWorkspaceData) {
        toastr.warning(
          'Service temporarily busy',
          'We\'re experiencing high demand. Your data will load shortly.',
          { timeOut: 5000 }
        );
      }
      
      // Attempt to retry with calculated backoff
      if (currentEntry.retryCount < RATE_LIMIT_RETRY_MAX) {
        // Use exponential backoff with jitter for AWS throttling
        if (isAwsThrottling) {
          retryDelay = calculateBackoff(currentEntry.retryCount);
        }
        
        if (!pendingRetries.has(requestKey)) {
          const timeoutId = setTimeout(() => {
            console.log(`Auto-retrying rate-limited request after ${retryDelay/1000}s: ${requestKey}`);
            pendingRetries.delete(requestKey);
            
            // Deep clone the config to ensure we don't reuse the same reference
            const retryConfig = JSON.parse(JSON.stringify(error.config));
            // For GraphQL requests, we need to recreate the data properly
            if (requestKey.includes('/graphql') && typeof error.config.data === 'string') {
              try {
                retryConfig.data = error.config.data;
              } catch (e) {
                console.error('Failed to parse GraphQL request data for retry:', e);
              }
            }
            
            // Make a fresh request with the cloned config
            axios(retryConfig).catch(err => {
              console.log('Retry attempt failed:', err.message);
            });
          }, retryDelay);
          
          pendingRetries.set(requestKey, timeoutId);
        }
      }
    }
    
    // Handle authentication errors
    if (error.response && error.response.status === 401) {
      authFailureCount++;
      console.log(`Authentication error detected (${authFailureCount}/${AUTH_MAX_FAILURES})`);
      
      // If we've had multiple auth failures, redirect to login
      if (authFailureCount >= AUTH_MAX_FAILURES) {
        // Reset the counter
        authFailureCount = 0;
        
        // Notify the user
        toastr.error('Session expired', 'Please login again', {
          timeOut: 3000,
          position: 'top-center'
        });
        
        // Dispatch a custom event for the auth context to listen for
        window.dispatchEvent(new CustomEvent('auth-expired'));
        
        // Redirect after a short delay
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000);
      }
    }
    
    return Promise.reject(error);
  }
);

// Add a window event handler to retry loading data if the app is in focus
// (helps recover from rate limits when returning to app)
window.addEventListener('focus', () => {
  // If we have any rate limited requests that are critical, retry them
  if (rateLimitedRequests.size > 0) {
    for (const [url, info] of rateLimitedRequests.entries()) {
      const isWorkspaceUrl = 
        url.includes('/graphql') || 
        url.includes('/api/workspaces') || 
        url.includes('/api/user');
      
      if (isWorkspaceUrl && Date.now() > info.resetTime) {
        console.log(`Window focus: retrying previously rate-limited request: ${url}`);
        rateLimitedRequests.delete(url);
        axios.get(url).catch(() => {}); // Fire and forget
      }
    }
  }
});

// Helper to reload workspace data - exported for use elsewhere
export const reloadWorkspaceData = async () => {
  try {
    const result = await axios.get('/api/self/subscription');
    // Dispatch event to notify components of successful reload
    window.dispatchEvent(new CustomEvent('workspace-data-reloaded', { detail: result.data }));
    return result;
  } catch (error) {
    console.error('Error reloading workspace data:', error);
    return null;
  }
};

export default { reloadWorkspaceData }; 