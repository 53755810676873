import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FiUpload } from 'react-icons/fi';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';

const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB
const validTypes = ['application/pdf'];

const Dropzone = ({ element, submit, cancel, isDark = false, result, children, className }) => {
  const [isDragging, setDragging] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Memoize handlers to prevent unnecessary re-renders
  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }, []);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    if (!isDragging) setDragging(true);
  }, [isDragging]);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragging(false);
  }, []);

  const finishUpload = useCallback(() => {
    setDragging(false);
    setLoading(false);
  }, []);

  const validateFile = useCallback((file) => {
    if (!validTypes.includes(file.type)) {
      toastr.error(t('toastr.This file type is not supported'));
      return false;
    }
    if (file.size > MAX_FILE_SIZE) {
      toastr.error(t('toastr.File size exceeds 200MB limit'));
      return false;
    }
    return true;
  }, [t]);

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;

    if (files.length) {
      const file = files[0];
      if (validateFile(file)) {
        setLoading(true);
        try {
          await submit(files);
        } catch (error) {
          console.error(error);
          toastr.error(t('toastr.Error uploading file'));
        } finally {
          finishUpload();
        }
      } else {
        finishUpload();
      }
    }
  }, [submit, validateFile, finishUpload, t]);

  const cancelUpload = useCallback((e) => {
    if ((isDragging || isLoading) && !!cancel) {
      e.preventDefault();
      setDragging(false);
      setLoading(false);
      cancel();
    }
  }, [isDragging, isLoading, cancel]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') cancelUpload(e);
  }, [cancelUpload]);

  // Set up event listeners
  useEffect(() => {
    if (element) {
      element.addEventListener('dragenter', handleDragEnter);
      element.addEventListener('dragover', handleDragOver);
      element.addEventListener('dragleave', handleDragLeave);
      element.addEventListener('drop', handleDrop);

      return () => {
        element.removeEventListener('dragenter', handleDragEnter);
        element.removeEventListener('dragover', handleDragOver);
        element.removeEventListener('dragleave', handleDragLeave);
        element.removeEventListener('drop', handleDrop);
      };
    }
  }, [element, handleDragEnter, handleDragOver, handleDragLeave, handleDrop]);

  useEffect(() => {
    if (result) finishUpload();
  }, [result, finishUpload]);

  useEffect(() => {
    if (cancel) {
      window.addEventListener('click', cancelUpload);
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('click', cancelUpload);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [cancel, cancelUpload, handleKeyDown]);

  return (
    <Wrapper 
      className={className} 
      onClick={cancelUpload}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label={t('Drop your PDF file here to upload')}
      aria-busy={isLoading}
    >
      <DropOverlay isDragging={isDragging} aria-hidden="true">
        <DropContent>
          {isLoading ? (
            <BeatLoader color="#ffffff" size={10} margin={4} />
          ) : (
            <>
              <Icon aria-hidden="true" />
              <DropMessage>{t('Drop your PDF file here to upload')}</DropMessage>
            </>
          )}
        </DropContent>
      </DropOverlay>
      <ChildrenContainer
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        isLoading={isLoading}
      >
        {children}
      </ChildrenContainer>
    </Wrapper>
  );
};

Dropzone.propTypes = {
  element: PropTypes.object,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  isDark: PropTypes.bool,
  result: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
};

Dropzone.defaultProps = {
  isDark: false,
};

const Wrapper = styled.div`
  position: relative;
  outline: none;
  &:focus-visible {
    box-shadow: 0 0 0 2px var(--primary-color);
    border-radius: 4px;
  }
`;

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${(props) => (props.isDragging ? 0.6 : 0)});
  display: ${(props) => (props.isDragging ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
  transition: background-color 0.3s ease, opacity 0.3s ease;
`;

const DropContent = styled.div`
  text-align: center;
  color: #fff;
  font-size: 20px;
  border: 3px dashed #fff;
  padding: 24px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isDragging ? 'scale(1.05)' : 'scale(1)')};
  
  svg {
    color: #fff;
  }
`;

const Icon = styled(FiUpload)`
  font-size: 40px;
  margin-bottom: 12px;
  color: #fff;
`;

const DropMessage = styled.div`
  font-size: 18px;
  color: #fff;
`;

const ChildrenContainer = styled.div`
  opacity: ${(props) => (props.isDragging ? 0.3 : 1)};
  transition: opacity 0.3s ease;
`;

export default memo(Dropzone);
