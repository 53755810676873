import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useAnalytics } from 'use-analytics';
import { UserPresentations, DashboardDropzone } from 'src/modules/presentations';
import LatestActivity from 'src/modules/navigation/main-sidebar/components/LatestActivity';
import useUserContext from 'src/context/UserContext';
import { workspaceContext } from 'src/context';
import { FiUpload } from 'react-icons/fi';

const PresentationsPage = () => {
  const { page } = useAnalytics();
  const { user } = useUserContext();
  const { presentations, uploadDocument } = useContext(workspaceContext);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const dropzoneRef = useRef(null);

  // Memoize presentations to prevent unnecessary re-renders
  const memoizedPresentations = useMemo(() => presentations, [presentations]);

  // Track page view on component mount
  useEffect(() => {
    page();
  }, [page]);

  // Show a loading state if presentations are null or undefined
  if (!presentations) {
    return <LoadingMessage>Loading workspace...</LoadingMessage>;
  }

  const isEmpty = presentations.length === 0;

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files?.length) {
      try {
        await uploadDocument(files[0]);
        // Reset the input value to allow selecting the same file again
        event.target.value = '';
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files?.length) {
      try {
        await uploadDocument(files[0]);
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  return (
    <DashboardDropzone ref={dropzoneRef}>
      <Wrapper>
        <DashboardContainer role="main" aria-label="Presentations Dashboard">
          {isEmpty ? (
            <EmptyStateContainer>
              <DropzoneArea 
                onDragEnter={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setIsDragging(false);
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                isDragging={isDragging}
              >
                <EmptyStateContent isDragging={isDragging}>
                  <EmptyStateTitle>Folder empty</EmptyStateTitle>
                  <EmptyStateDescription>Add a document to get started.</EmptyStateDescription>
                  <HiddenFileInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".pdf"
                    aria-label="Upload PDF file"
                  />
                  <UploadButton onClick={handleUploadClick} type="button">
                    <UploadIcon aria-hidden="true" />
                    Upload a PDF
                  </UploadButton>
                  <DragDropHint>
                    or drag and drop your PDF here
                  </DragDropHint>
                </EmptyStateContent>
                <DropOverlay isDragging={isDragging} aria-hidden={!isDragging}>
                  <DropIcon />
                  <DropText>Drop to upload your PDF</DropText>
                </DropOverlay>
              </DropzoneArea>
            </EmptyStateContainer>
          ) : (
            <>
              <UserPresentations presentations={memoizedPresentations} />
              <LatestActivity user={user} />
            </>
          )}
        </DashboardContainer>
      </Wrapper>
    </DashboardDropzone>
  );
};

const DashboardContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  & > *:first-of-type {
    flex-grow: 5;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--text-muted-color);
`;

const EmptyStateContainer = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 40px auto;
  position: relative;
`;

const DropzoneArea = styled.div`
  width: 100%;
  min-height: 260px;
  padding: 40px 32px;
  background: var(--background-color);
  border-radius: 12px;
  border: 1px dashed ${props => props.isDragging ? 'var(--primary-color)' : 'var(--border-color)'};
  position: relative;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  &:hover {
    border-color: var(--primary-color);
    background: var(--background-hover-color);
  }

  ${props => props.isDragging && `
    border-width: 2px;
    background: var(--background-hover-color);
    transform: scale(1.01);
    box-shadow: 0 4px 12px var(--primary-shadow-color);
  `}

  @media (max-width: 768px) {
    min-height: 240px;
    padding: 32px 24px;
  }

  @media (max-width: 480px) {
    min-height: 220px;
    padding: 24px 20px;
  }
`;

const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  opacity: ${props => props.isDragging ? 0.3 : 1};
  transform: scale(${props => props.isDragging ? 0.98 : 1});
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

const EmptyStateTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  color: var(--text-color);
  margin: 0 0 8px;
  line-height: 1.2;
  letter-spacing: -0.02em;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const EmptyStateDescription = styled.p`
  font-size: 16px;
  color: var(--text-secondary-color);
  margin: 0 0 24px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const UploadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: left 0.5s ease;
  }

  &:hover {
    transform: translateY(-1px);
    filter: brightness(1.1);
    box-shadow: 0 4px 12px var(--primary-shadow-color);

    &::before {
      left: 100%;
      transition: left 0.8s ease;
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px var(--primary-shadow-color);
  }

  &:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    font-size: 14px;
  }
`;

const UploadIcon = styled(FiUpload)`
  font-size: 18px;
  stroke-width: 2;
  transition: transform 0.2s ease;
  color: white;

  ${UploadButton}:hover & {
    transform: translateY(-2px);
  }
`;

const DragDropHint = styled.p`
  margin: 12px 0 0;
  font-size: 14px;
  color: var(--text-muted-color);
  opacity: 0.8;
  transition: opacity 0.2s ease;

  ${DropzoneArea}:hover & {
    opacity: 1;
  }
`;

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-hover-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.isDragging ? 1 : 0};
  visibility: ${props => props.isDragging ? 'visible' : 'hidden'};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
`;

const DropIcon = styled(FiUpload)`
  font-size: 32px;
  color: var(--primary-color);
  margin-bottom: 12px;
  animation: float 2s ease-in-out infinite;

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }
`;

const DropText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  opacity: 0.9;
`;

const HiddenFileInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export default PresentationsPage;
