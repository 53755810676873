import React, { useRef, useEffect, useState, useCallback } from 'react'
import { zoomIn, zoomOut } from 'src/design-system/icons'
// @ts-ignore - Using module without type definitions
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { useAuth } from 'oidc-react'
import { useNavigate, useParams } from 'react-router-dom'
import { min, addMinutes, differenceInDays, differenceInSeconds } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
// @ts-ignore - Using module without type definitions
import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import { PinScreen, EmailScreen, useLeadOpenEvents, usePresentationAnalytics } from 'src/modules/player'
// @ts-ignore - Using module without type definitions
import QuickPinchZoom from 'react-quick-pinch-zoom'
import ViewerHeader from './ViewerHeader'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { useUserContext } from 'src/context'
import SizleLoader from 'src/modules/controls/components/SizleLoader'
import { ReactionsWidget } from 'src/modules/reactions'
import { useWindowSize } from 'src/modules/controls/hooks/useWindowSize'

function usePrevious(value: any) {
  const ref = useRef<any>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const ViewerPage = () => {
  const navigate = useNavigate()
  const { linkId } = useParams()
  const { user } = useUserContext()
  const { isLoading } = useAuth()
  const [currentLeadId, setCurrentLeadId] = useState<any>(null)
  const { createPublicLead, updateLeadOpenEvents } = useLeadOpenEvents()
  const { presentationStart, viewSlide, presentationEnded } = usePresentationAnalytics()
  const [_, height] = useWindowSize()

  const oldPresentationEnded = usePrevious(presentationEnded)
  const [showingPinScreen, setShowingPinScreen] = useState(false)
  const [showingEmailScreen, setShowingEmailScreen] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [failedPinCount, setFailedPinCount] = useState(0)
  const [documentLoaded, setDocumentLoaded] = useState(false)
  const [linkDetails, setLinkDetails] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [docZoom, setDocZoom] = useState(1)
  const [_downloadEnabled, setDownloadEnabled] = useState(true)
  const [pin, setPin] = useState('')
  const observerRef = useRef<IntersectionObserver | null>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const rafRef = useRef<number | null>(null);
  const pageChangingRef = useRef(false);

  const fetchLinkDetails = async () => {
    // Get link details
    const { data: linkDetails } = await axios({
      url: `/api/links/${linkId}`
    })
    setLinkDetails(linkDetails)

    setShowingEmailScreen(linkDetails?.requiresEmailCapture && !user?.email)
    // Determine lead id
    let leadId = linkDetails?.emailLead?.leadUuId || linkDetails?.staticLead?.leadId
    if (!leadId && user?.email) leadId = await createPublicLead(linkId, user.email)
    if (!leadId && !linkDetails.requiresEmailCapture && !user?.email) leadId = await createPublicLead(linkId)
    if (leadId) setCurrentLeadId(leadId)
    // Burn timer and expiration date
    const { burnTimer, expirationDate } = linkDetails
    if (burnTimer || expirationDate) {
      const dates = []
      if (burnTimer) dates.push(addMinutes(linkDetails.earliestActivity ? new Date(linkDetails.earliestActivity) : new Date(), burnTimer))
      if (expirationDate) dates.push(new Date(expirationDate))
      const expireAt = min(dates)
      handleExpiry(expireAt)
    }
  }

  const handleExpiry = (expireAt: Date) => {
    const secondsRemaining = differenceInSeconds(expireAt, new Date())
    if (differenceInDays(expireAt, new Date()) < 20) {
      setTimeout(() => navigate(`/expired-link/${linkId}`, { replace: true }), secondsRemaining * 1000)
    } else {
      setTimeout(() => handleExpiry(expireAt), 1440000)
    }
  }

  // Wait for document loaded, link metadat retrieved and lead created, then post analytics
  useEffect(() => {
    if (currentLeadId && linkDetails && documentLoaded) {
      if (!linkDetails?.downloads_enabled) {
        setDownloadEnabled(false)
      }
      updateLeadOpenEvents({ leadId: currentLeadId })
      presentationStart({
        presentationId: linkDetails.presentation.presentationId,
        leadId: currentLeadId,
        linkId,
        workspaceId: linkDetails.presentation.workspace_id
      })
    }
  }, [currentLeadId, linkDetails, documentLoaded, linkId])

  useEffect(() => {
    console.log('should fetch link details', isLoading)
    if (!isLoading) {
      fetchLinkDetails().catch(e => console.error(e))
    }
  }, [isLoading])

  // Add document closed analytics
  oldPresentationEnded && window.removeEventListener('beforeunload', oldPresentationEnded)
  window.addEventListener('beforeunload', presentationEnded)

  // Try load document with newly entered pin
  const handlePinEntry = async (pin: string) => {
    setPin(pin)
  }

  const onDocumentLoadFailure = async ({ status }: {status: number}) => {
    if (status === 400) {
      toastr.error('Document may not be served from this custom domain.')
    } else if (status === 403 || status === 401) {
      setFailedPinCount(p => p + 1)
      setShowingPinScreen(true)
      setLoadingDocument(false)
      setDocumentLoaded(true)
    } else {
      toastr.error('Unable to load document, please contact support!')
      console.error('Failed to load document')
    }
  }

  function onDocumentLoadSuccess ({ numPages }: {numPages: number}) {
    setNumPages(numPages)
    sendAmplitudeData('VIEWING_DOCUMENT')
    setDocumentLoaded(true)
    setShowingPinScreen(false)
    
    // Setup page tracking after document loads with a slightly longer delay
    // to ensure all pages are fully rendered
    setTimeout(() => {
      setupPageObserver();
    }, 1000);
  }

  // Setup intersection observer to track visible pages when scrolling
  const setupPageObserver = useCallback(() => {
    // Disconnect previous observer if it exists
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create new intersection observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        // Skip if we're in the middle of a programmatic page change
        if (pageChangingRef.current) return;
        
        // Get all pages that are at least 30% visible, sorted by visibility ratio
        const visiblePages = entries
          .filter(entry => entry.isIntersecting && entry.intersectionRatio > 0.2)
          .sort((a, b) => b.intersectionRatio - a.intersectionRatio); // Sort by most visible first

        // Update current page if we have a visible page
        if (visiblePages.length > 0) {
          const newPage = parseInt(visiblePages[0].target.id.replace('page-', ''));
          if (newPage !== page) {
            setPage(newPage);
          }
        }
      },
      {
        root: document.querySelector('.viewer-doc'),
        threshold: [0.1, 0.2, 0.3, 0.5, 0.7, 0.9], // More granular thresholds
        rootMargin: '-10px 0px' // Slight offset to improve detection
      }
    );

    // Observe all page elements
    if (numPages) {
      for (let i = 1; i <= numPages; i++) {
        const pageEl = document.getElementById(`page-${i}`);
        if (pageEl) {
          observerRef.current.observe(pageEl);
        }
      }
    }
  }, [numPages, page]);

  // Cleanup observer on component unmount
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  // Monitor scroll events as a fallback method
  useEffect(() => {
    const handleScroll = () => {
      if (!numPages || !documentLoaded) return;
      // Skip if we're in the middle of a programmatic page change
      if (pageChangingRef.current) return;
      
      const viewerDoc = document.querySelector('.viewer-doc');
      if (!viewerDoc) return;
      
      // Cancel any existing animation frame
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      
      // Use requestAnimationFrame for smoother handling
      rafRef.current = requestAnimationFrame(() => {
        // Find which page is most visible in the viewport
        let maxVisiblePage = 1;
        let maxVisibleArea = 0;
        
        for (let i = 1; i <= numPages; i++) {
          const pageEl = document.getElementById(`page-${i}`);
          if (!pageEl) continue;
          
          const rect = pageEl.getBoundingClientRect();
          const viewerRect = viewerDoc.getBoundingClientRect();
          
          // Calculate visible area of the page
          const visibleTop = Math.max(rect.top, viewerRect.top);
          const visibleBottom = Math.min(rect.bottom, viewerRect.bottom);
          const visibleHeight = Math.max(0, visibleBottom - visibleTop);
          
          // Calculate visibility as a ratio of the element's height
          const visibleRatio = visibleHeight / rect.height;
          
          // Weight the calculation to favor pages that are more fully visible
          // and consider both the ratio and absolute area
          let visibleArea = visibleHeight * rect.width;
          
          // Boost score for pages with more than 40% visibility
          if (visibleRatio > 0.4) {
            visibleArea *= 1.5;
          }
          
          // Further boost for center-positioned pages
          const elementCenter = rect.top + rect.height / 2;
          const viewportCenter = viewerRect.top + viewerRect.height / 2;
          const distanceFromCenter = Math.abs(elementCenter - viewportCenter);
          const centerBonus = 1 - (distanceFromCenter / (viewerRect.height / 2)) * 0.5;
          visibleArea *= Math.max(0.5, centerBonus);
          
          if (visibleArea > maxVisibleArea) {
            maxVisibleArea = visibleArea;
            maxVisiblePage = i;
          }
        }
        
        if (maxVisiblePage !== page && maxVisibleArea > 0) {
          setPage(maxVisiblePage);
        }
      });
    };
    
    const viewerDoc = document.querySelector('.viewer-doc');
    if (viewerDoc && documentLoaded) {
      viewerDoc.addEventListener('scroll', handleScroll);
      // Initial check for current page
      setTimeout(handleScroll, 200);
    }
    
    return () => {
      if (viewerDoc) {
        viewerDoc.removeEventListener('scroll', handleScroll);
      }
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [numPages, page, documentLoaded]);

  // Navigate to page and ensure analytics are tracked
  const navigateToPage = useCallback((newPage: number) => {
    if (newPage < 1 || (numPages && newPage > numPages)) return;
    
    const pageElement = document.getElementById(`page-${newPage}`);
    if (!pageElement) return;
    
    // Set flag to prevent scroll handlers from triggering during programmatic scrolling
    pageChangingRef.current = true;
    
    // Set page immediately for UI feedback
    setPage(newPage);
    
    // Scroll to the page
    pageElement.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start'
    });
    
    // Reset flag after scrolling completes
    setTimeout(() => {
      pageChangingRef.current = false;
    }, 800); // Slightly longer than typical scroll animation
  }, [numPages]);

  // Update arrow key handler to use our navigateToPage function
  const handleArrowKeys = useCallback((e) => {
    if (e.key === 'ArrowRight') {
      navigateToPage(page + 1);
    } else if (e.key === 'ArrowLeft') {
      navigateToPage(page - 1);
    }
  }, [page, navigateToPage]);

  useEffect(() => {
    viewSlide(page)
  }, [page, viewSlide])

  // Use viewer entered email to create public link
  const handleEmailEntry = async (email: string) => {
    const leadId = await createPublicLead(linkId, email)
    setCurrentLeadId(leadId)
    setShowingEmailScreen(false)
  }
  const handleZoom = useCallback(
    (zoomChange: number) => {
      setDocZoom((prevZoom) => {
        const newZoom = Math.max(0.5, Math.min(prevZoom + zoomChange, 3)); // Restrict zoom between 0.5x and 3x
  
        const viewer = document.querySelector('.viewer-doc');
        if (viewer) {
          const { scrollLeft, scrollTop, clientWidth, clientHeight } = viewer;
  
          // Calculate the center of the viewport relative to the current zoom level
          const centerX = (scrollLeft + clientWidth / 2) / prevZoom;
          const centerY = (scrollTop + clientHeight / 2) / prevZoom;
  
          // Adjust scroll positions based on the new zoom level
          const newScrollLeft = centerX * newZoom - clientWidth / 2;
          const newScrollTop = centerY * newZoom - clientHeight / 2;
  
          // Apply the new scroll positions, ensuring they stay within bounds
          viewer.scrollTo({
            left: Math.max(0, newScrollLeft),
            top: Math.max(0, newScrollTop),
            behavior: 'auto', // Use 'smooth' for smoother transitions if needed
          });
        }
  
        return newZoom;
      });
    },
    []
  );

  // Fake QuickPinchZoom onUpdate handler (required by component)
  const handlePinchZoomUpdate = useCallback(({ x, y, scale }: { x: number, y: number, scale: number }) => {
    // This is required by the component but we don't need to do anything with it
    // console.log('Pinch zoom update:', { x, y, scale });
  }, []);

  return (
    <Wrapper onKeyUp={handleArrowKeys}>
      <Header>
      </Header>
  
      {/* Controls Positioned at Top Center */}
      <div className="page-controls">
        <span className="page-info">
          {page} / {numPages || 1}
        </span>
        <button
          className="btn"
          onClick={() => navigateToPage(page - 1)}
          disabled={page === 1}
        >
          Prev
        </button>
        <button
          className="btn"
          onClick={() => navigateToPage(page + 1)}
          disabled={page === numPages}
        >
          Next
        </button>
        <button className="btn" onClick={() => setDocZoom((zoom) => zoom - 0.2)} title="Zoom Out">
          <SVGInline svg={zoomOut} />
        </button>
        <button className="btn" onClick={() => setDocZoom((zoom) => zoom + 0.2)} title="Zoom In">
          <SVGInline svg={zoomIn} />
        </button>
      </div>

      {!documentLoaded && <SizleLoader>{null}</SizleLoader>}
  
      <ViewerWrapper hidden={!documentLoaded}>
        {linkDetails && (
          <ViewerHeader
            linkId={linkId}
            linkDetails={linkDetails}
            currentLeadId={currentLeadId}
          />
        )}
        <ViewerContainer>
          {document !== null && (
            <div className="viewer-doc">
              <QuickPinchZoom onUpdate={handlePinchZoomUpdate}>
                <Document
                  file={`/api/links/${linkId}/file${pin ? `?pin=${pin}` : ''}`}
                  onLoadError={onDocumentLoadFailure}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages || 0), (_, index) => (
                    <PageWrapper key={index} id={`page-${index + 1}`}>
                      <Page pageNumber={index + 1} scale={docZoom} />
                    </PageWrapper>
                  ))}
                </Document>
              </QuickPinchZoom>
            </div>
          )}
        </ViewerContainer>
        {linkDetails?.reactions_enabled && documentLoaded && (
          <ReactionsWidget leadId={currentLeadId} isPreview={false} />
        )}
      </ViewerWrapper>
  
      {showingPinScreen && (
        <PinScreen
          checkPinInput={(p: string) => handlePinEntry(p)}
          incorrectPinAttempted={failedPinCount}
          loading={loadingDocument}
        />
      )}
      {showingEmailScreen && (
        <EmailScreen 
          onSubmit={handleEmailEntry} 
          isLoading={false}
          previewMode={false}
        />
      )}
    </Wrapper>
  );
  
};

const Wrapper = styled.div`
  height: 100%;
  background: #383838;

  .page-info {
  font-size: 0.875rem;
  color: white;
  margin-right: 16px;
 background-color: rgba(51, 51, 51, 0.5); /* Half-opacity */
  padding: 8px 28px;
  border-radius: 4px;
}

  .viewer-doc {
    overflow: auto;
    height: calc(100vh);
    position: relative;
  }
  
  .page-btns {
    bottom: 20px;
    position: absolute;
}

.viewer-doc {
    overflow: auto;
    height: calc(100vh)
      display: inline-block;;
    position: relative;
  }

  .page-controls {
    position: fixed;
    top: 1em; /* Positioned below the header */
    left: 16%;
    transform: translateX(-50%); /* Horizontally center the controls */
    z-index: 1050; /* Ensure controls are on top */
    display: flex;
      margin-left: 33%;
    gap: 8px;

    .btn {
      background: #000;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #4631e9;
      }

      &:disabled {
        background: #555;
        cursor: not-allowed;
      }
    }
  }
`;

const ViewerWrapper = styled.div`
  height: calc(100vh - 60px); /* Account for header height */
  align-items: center; /* Center horizontally */
  padding: 16px 0; /* Add padding around the pages */
`;

const ViewerContainer = styled.div`
  width: 100%;
  flex-direction: column; /* Stack pages vertically */
  align-items: center; /* Center horizontally */
`;

const PageWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: white;
  scroll-snap-align: start; /* Ensure scrolling aligns pages correctly */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #202020;
  color: white;
  z-index: 102; /* Increased z-index for visibility above content */
  position: sticky; /* Sticks to the top of the viewport */
  top: 0;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  gap: 8px;

  .btn {
    background: #000;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #4631e9;
    }

    &:disabled {
      background: #555;
      cursor: not-allowed;
    }
  }
`;

const DocumentWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust height to leave space for controls */
  display: inline-block;
  flex-direction: column; /* Ensure vertical layout */
  align-items: center;
`;

export default ViewerPage
