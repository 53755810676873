import React, { memo, useState, useCallback } from 'react';
import styled from '@emotion/styled';

const ResponseForm = ({ onSubmit, refreshComments }) => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleTextChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const handleSubmit = async () => {
    if (text && text.length > 0) {
      setLoading(true);
      try {
        if (onSubmit) {
          await onSubmit(text);
          setText(''); // Clear the text input after submission
          refreshComments(); // Trigger a refresh of the comment list after submission
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      {isOpen && <Overlay onClick={toggleOpen} />} {/* Shadow overlay */}
      <Wrapper>
        <ResponseInput
          value={text}
          onChange={handleTextChange}
          placeholder='Join the discussion'
          style={{ color: 'white' }} // Ensures text is white
        />
        <StyledButton
          className='btn primary'
          loading={loading ? 'loading' : undefined}
          disabled={loading || text.length === 0} // Disables the button only when loading or input is empty
          onClick={handleSubmit}
        >
          Post
        </StyledButton>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121211;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 101; // Ensure it's above the overlay
`;

const ResponseInput = styled.textarea`
  width: 100%;
  height: 120px;
  background: #1a1918;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  outline: none;
  resize: none;
  color: white; // Ensures text is white
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: rgba(0, 0, 255, 0.5);
  }
`;

const StyledButton = styled.button`
  margin-top: 0px;
  padding: 8px 16px;
  background-color: #5843FF;  // Primary color
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #5843FF;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: #4631e9;
  }
`;

export default memo(ResponseForm);
