import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import GooglePicker from 'react-google-picker';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { useLocation } from 'react-router-dom';
import { MenuList, MenuItem } from '@chakra-ui/core';
import { ModeContext, useUserContext, workspaceContext } from 'src/context';
import styles from 'src/styles/components/upload-dropdown.module.scss';

const UploadDropdown = ({ onOpen, onClose, onCreateNewFolder, folderId }) => {
  const { importDocument, uploadDocument, createDraftPresentation, hasWorkspacePermission } = useContext(workspaceContext);
  const { hideSizleBrand } = useContext(ModeContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#create-document') {
      setTimeout(() => onOpen(), 1);
    }
  }, [location, onOpen]);

  const onClickCreateFolder = (e) => {
    onClose();
    onCreateNewFolder(folderId);
  };

  const onFileUpload = async (event) => {
    try {
      onClose();
      const file = event.target.files[0];
      if (file) {
        if (file.type !== 'application/pdf') {
          setErrorMessage('Only PDF files are supported at this time.');
          setShowError(true);
          setTimeout(() => setShowError(false), 3000); // Fade out after 3 seconds
          event.target.value = ''; // Clear the file input
          return;
        }
        setErrorMessage(''); // Clear any existing error messages
        setShowError(false);
        await uploadDocument(file);
      }
    } catch (e) {
      if (e.error === 'Document limit exceeded' || e.error === 'Storage limit exceeded') {
        console.error('Storage limit exceeded!');
      }
    }
  };

  const onChooseDropboxFile = () => {
    if (window.Dropbox && window.Dropbox.isBrowserSupported()) {
      window.Dropbox.choose({
        success: async (file) => {
          await importDocument({
            url: file[0].link,
            title: file[0].name,
          });
          sendAmplitudeData('IMPORTING_DROPBOX_DOCUMENT');
          onClose();
        },
        cancel: () => {},
        linkType: 'direct',
        multiselect: false,
        extensions: import.meta.env.VITE_VIEWER_FILE_FORMATS.split(','),
        folderselect: false,
        sizeLimit: 200000000, // 200 Mb
      });
    }
  };

  return (
    <div className={styles['dropdown-container']} style={{ position: 'relative' }}>
      <MenuList className={styles['menu-list']} placement='bottom-start'>
        {onCreateNewFolder && hasWorkspacePermission('Manage Folders') && (
          <MenuItem onClick={onClickCreateFolder} className={styles['menu-item']}>
            Create new folder
          </MenuItem>
        )}
        {hasWorkspacePermission('Add & Edit Documents') && (
          <>
            <MenuItem as='label' htmlFor='upload-dropdown-file-upload' className={styles['menu-item']}>
              Upload a file
            </MenuItem>
            <MenuItem 
              isDisabled={hideSizleBrand} 
              onClick={onChooseDropboxFile} 
              className={styles['menu-item']}
              title={hideSizleBrand ? 'Dropbox is not currently supported while using the site on a custom domain' : ''}
            >
              Import from Dropbox
            </MenuItem>
          </>
        )}
      </MenuList>
      <input hidden id='upload-dropdown-file-upload' type='file' accept=".pdf" onChange={onFileUpload} />
      {showError && (
        <div
          className={styles['error-message']}
          style={{
            position: 'absolute',
            top: '50%',
            width: '100%',
            left: '100%',
            marginLeft: '10px',
            transform: 'translateY(-50%)',
            backgroundColor: '#ffe6e6',
            color: '#e63946',
            padding: '5px 10px',
            borderRadius: '4px',
            transition: 'opacity 0.5s ease-in-out',
            opacity: showError ? 1 : 0,
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

UploadDropdown.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onCreateNewFolder: PropTypes.func,
  folderId: PropTypes.number,
};

export default UploadDropdown;
