import { useEffect } from 'react';

type KeyboardShortcut = string;
type KeyboardEventHandler = (e: KeyboardEvent) => void;

const parseShortcut = (shortcut: KeyboardShortcut): { key: string; cmd: boolean; shift: boolean; alt: boolean } => {
  const parts = shortcut.toLowerCase().split('+');
  return {
    key: parts[parts.length - 1],
    cmd: parts.includes('cmd') || parts.includes('meta'),
    shift: parts.includes('shift'),
    alt: parts.includes('alt'),
  };
};

export const useKeyboardShortcut = (shortcut: KeyboardShortcut, handler: KeyboardEventHandler): void => {
  useEffect(() => {
    const { key, cmd, shift, alt } = parseShortcut(shortcut);

    const handleKeyDown = (e: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const cmdKey = isMac ? e.metaKey : e.ctrlKey;

      if (
        e.key.toLowerCase() === key &&
        cmdKey === cmd &&
        e.shiftKey === shift &&
        e.altKey === alt
      ) {
        handler(e);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [shortcut, handler]);
}; 