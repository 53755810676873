import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiX } from 'react-icons/fi';
import { useAuth } from 'oidc-react';
import { useNavigate, useLocation } from 'react-router-dom';

import StaticLinkTab from '../../presentations/components/popup/StaticLinkTab';
import CreatePublicLinkTab from '../../presentations/components/popup/CreatePublicLinkTab';
import SendMailTab from '../../presentations/components/popup/SendMailTab';

const CONTENT_TABS = ['public', 'addons'];

const ShareModal = ({
  dismissModal,
  presentationId,
  title,
  url,
  openTrackingEnabled,
  emailNotificationsEnabled,
  shareType,
}) => {
  const [contentTab, setContentTab] = useState(CONTENT_TABS[0]);
  const [showSuccess, setShowSuccess] = useState(false);
  const { userDetails: user } = useAuth();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const closeButtonRef = useRef(null);
  const modalRef = useRef(null);

  const onClose = async () => {
    if (hash === '#share') {
      navigate('#');
    }
    dismissModal();
  };

  useEffect(() => {
    setContentTab(CONTENT_TABS[0]);
    
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
    
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    const handleFocusTrap = (e) => {
      if (!modalRef.current) return;
      
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];
      
      if (e.key === 'Tab') {
        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
        }
      }
    };
    
    window.addEventListener('keydown', handleFocusTrap);
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keydown', handleFocusTrap);
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleSuccessState = (success) => {
    setShowSuccess(success);
  };

  const renderTabContent = () => {
    switch (contentTab) {
      case 'public':
        return (
          <CreatePublicLinkTab 
            presentationId={presentationId} 
            user={user} 
            closeModal={onClose} 
            onSuccessStateChange={handleSuccessState}
          />
        );
      case 'links':
        return (
          <StaticLinkTab
            presentationId={presentationId}
            presentationTitle={title}
            url={url}
            user={user}
            openTrackingEnabled={openTrackingEnabled}
            emailNotificationsEnabled={emailNotificationsEnabled}
            setPreviousTitle={() => {}}
            closePopup={onClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalOverlay role="dialog" aria-modal="true" aria-labelledby={showSuccess ? undefined : "modal-title"}>
      <ModalBackdrop onClick={onClose} />
      <ModalContainer ref={modalRef}>
        <ModalContent>
          {!showSuccess && (
            <ModalHeader>
              <ModalTitle id="modal-title">Share document</ModalTitle>
              <CloseButton 
                onClick={onClose} 
                aria-label="Close dialog"
                ref={closeButtonRef}
              >
                <FiX size={20} />
              </CloseButton>
            </ModalHeader>
          )}
          {showSuccess && (
            <SuccessHeader>
              <CloseButton 
                onClick={onClose} 
                aria-label="Close dialog"
                ref={closeButtonRef}
              >
                <FiX size={20} />
              </CloseButton>
            </SuccessHeader>
          )}
          <TabContent>{renderTabContent()}</TabContent>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
`;

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 480px;
  background: color-mix(in srgb, var(--background-color) 90%, white);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  animation: modalEnter 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1;
  
  @keyframes modalEnter {
    0% {
      opacity: 0;
      transform: scale(0.95) translateY(10px);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  @media (max-width: 520px) {
    max-width: 100%;
    margin: 0 8px;
    border-radius: 12px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border-bottom: 1px solid var(--border-color);
`;

const SuccessHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 0;
`;

const ModalTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
  line-height: 1.3;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background: none;
  border: none;
  border-radius: 8px;
  color: var(--text-secondary-color);
  cursor: pointer;
  transition: all 0.2s ease;
  margin: -6px;

  &:hover {
    background-color: var(--background-hover-color);
    color: var(--text-color);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const TabContent = styled.div`
  background: var(--background-color);
`;

export default ShareModal;