import React from 'react';
import styled from '@emotion/styled';

const LabeledToggle = ({
  title,
  subtitle,
  defaultChecked,
  onChange,
  disabled = false,
  ...props
}) => {
  const id = `toggle-${title.toLowerCase().replace(/\s+/g, '-')}`;
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (!disabled) {
        onChange({ target: { checked: !defaultChecked } });
      }
    }
  };

  return (
    <Container className="labeled-toggle">
      <ToggleWrapper>
        <ToggleInput
          type="checkbox"
          id={id}
          checked={defaultChecked}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <ToggleSlider
          tabIndex={0}
          role="switch"
          aria-checked={defaultChecked}
          aria-disabled={disabled}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              onChange({ target: { checked: !defaultChecked } });
            }
          }}
        />
      </ToggleWrapper>
      <LabelContainer>
        <Label htmlFor={id}>
          {title}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Label>
      </LabelContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
`;

const LabelContainer = styled.div`
  flex: 1;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  display: block;
  line-height: 1.2;
`;

const Subtitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary-color);
  margin-top: 1px;
`;

const ToggleWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  
  &:focus + div {
    box-shadow: 0 0 0 2px var(--primary-color-faint, rgba(106, 90, 205, 0.3));
  }
`;

const ToggleSlider = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  background-color: ${props => 
    props.disabled 
      ? 'var(--background-muted, rgba(0, 0, 0, 0.1))' 
      : props['aria-checked'] 
        ? '#6a5acd' 
        : 'var(--background-muted, rgba(0, 0, 0, 0.1))'
  };
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.6 : 1};
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint, rgba(106, 90, 205, 0.3));
  }
  
  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    top: 50%;
    transform: ${props => props['aria-checked'] ? 'translate(20px, -50%)' : 'translate(0, -50%)'};
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
`;

export default LabeledToggle; 