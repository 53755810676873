import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { logoutIcon } from '@sizle-icons'
import { regenerateDeviceId, setAmplitudeUserId, sendAmplitudeData } from 'src/utils/amplitude'
import FooterCreateIcon from './FooterCreateIcon'
import { useAuth } from 'oidc-react'

const SidebarFooter = ({ compactNav }) => {
  const { signOutRedirect } = useAuth()
  const { t } = useTranslation()

  const onClickLogout = () => {
    sendAmplitudeData('USER_LOGGED_OUT')
    setAmplitudeUserId(null)
    regenerateDeviceId()
    signOutRedirect()
  }

  return (
    <div className='sidebar-actions'>
      <div className='sidebar-links'>
        <a className='footer-link' onClick={onClickLogout}>
          <FooterCreateIcon icon={logoutIcon} name='logout' />
          {!compactNav && <span>{t('Sign out')}</span>}
        </a>
      </div>
    </div>
  )
}

export default SidebarFooter
