import React, { useRef, useEffect, useState } from 'react';
import SVGInline from 'react-svg-inline';
import styled from '@emotion/styled';
import { link, barChart, messageCircle, team } from '@sizle-icons';
import './OnboardingSlider.css';

// Custom Slider component with our own slide mechanism
const OnboardingSlider = ({ dismissModal }) => {
  const modalRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const totalSlides = 2;

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      dismissModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleManualSlideChange = (index) => {
    if (isAnimating || index === currentSlide) return;
    
    setIsAnimating(true);
    setCurrentSlide(index);
    setTimeout(() => setIsAnimating(false), 500);
  };

  const goToNextSlide = () => {
    if (currentSlide < totalSlides - 1) {
      handleManualSlideChange(currentSlide + 1);
    } else {
      dismissModal();
    }
  };

  const goToPrevSlide = () => {
    if (currentSlide > 0) {
      handleManualSlideChange(currentSlide - 1);
    }
  };

  const getButtonText = () => {
    return currentSlide === totalSlides - 1 ? "Get started" : "Continue";
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      goToNextSlide();
    } else if (event.key === 'ArrowLeft') {
      goToPrevSlide();
    } else if (event.key === 'Escape') {
      dismissModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentSlide, isAnimating]);

  // Render slides directly
  const renderSlide = (index) => {
    const isVisible = currentSlide === index;
    
    if (index === 0) {
      return (
        <StyledSlide className={isVisible ? 'carousel__slide--visible' : 'carousel__slide--hidden'} key={`slide-${index}`}>
          <SlideContent className="illustration-container">
            <IllustrationContainer>
              <img src="/assets/illustrations/share-documents.svg" alt="Share documents" />
            </IllustrationContainer>
            <h2 className="slide-title">You're one link away from better leads</h2>
            <h3 className="slide-subtitle">
Upload a document. Share the link. Get real-time audience insights.
            </h3>
            <FeatureList>
              <Feature>
                <IconWrapper>
                  <SVGInline
                    svg={link
                      .replace(/fill=".*?"/g, '')
                      .replace(
                        /<path/g,
                        '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                      )}
                  />
                </IconWrapper>
                <FeatureText>
                  <FeatureTitle>Share your document in seconds</FeatureTitle>
                  <FeatureDescription>Create trackable links for any document with one click</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <IconWrapper>
                  <SVGInline
                    svg={barChart
                      .replace(/fill=".*?"/g, '')
                      .replace(
                        /<path/g,
                        '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                      )}
                  />
                </IconWrapper>
                <FeatureText>
                  <FeatureTitle>See who views and what they do</FeatureTitle>
                  <FeatureDescription>Track engagement with page-level analytics</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <IconWrapper>
                  <SVGInline
                    svg={messageCircle
                      .replace(/fill=".*?"/g, '')
                      .replace(
                        /<path/g,
                        '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                      )}
                  />
                </IconWrapper>
                <FeatureText>
                  <FeatureTitle>Collect new leads on autopilot</FeatureTitle>
                  <FeatureDescription>Capture emails with gated content form fields</FeatureDescription>
                </FeatureText>
              </Feature>
            </FeatureList>
          </SlideContent>
        </StyledSlide>
      );
    }
    
    if (index === 1) {
      return (
        <StyledSlide className={isVisible ? 'carousel__slide--visible' : 'carousel__slide--hidden'} key={`slide-${index}`}>
          <SlideContent className="illustration-container">
            <IllustrationContainer>
              <img src="/assets/illustrations/analytics-dashboard.svg" alt="Analytics dashboard" />
            </IllustrationContainer>
            <h2 className="slide-title">Track every interaction</h2>
            <h3 className="slide-subtitle">
              Know exactly how prospects engage with your content, what they're looking at most and when they're ready to convert.
            </h3>
            <FeatureList>
              <Feature>
                <IconWrapper>
                  <SVGInline
                    svg={barChart
                      .replace(/fill=".*?"/g, '')
                      .replace(
                        /<path/g,
                        '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                      )}
                  />
                </IconWrapper>
                <FeatureText>
                  <FeatureTitle>Detailed analytics dashboard</FeatureTitle>
                  <FeatureDescription>View engagement metrics and lead activity in real-time</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <IconWrapper>
                  <SVGInline
                    svg={team
                      .replace(/fill=".*?"/g, '')
                      .replace(
                        /<circle/g,
                        '<circle stroke="#6a5acd" fill="none" stroke-width="2"'
                      )
                      .replace(
                        /<path/g,
                        '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                      )}
                  />
                </IconWrapper>
                <FeatureText>
                  <FeatureTitle>Follow up at the perfect time</FeatureTitle>
                  <FeatureDescription>Get notified when prospects are most engaged</FeatureDescription>
                </FeatureText>
              </Feature>
            </FeatureList>
          </SlideContent>
        </StyledSlide>
      );
    }
    
    return null;
  };

  return (
    <SliderContainer ref={modalRef}>
      <CloseButton onClick={dismissModal} aria-label="Close" type="button">×</CloseButton>
      
      <SliderContent>
        {renderSlide(0)}
        {renderSlide(1)}
      </SliderContent>
      
      <SlideControls>
        <ProgressDots>
          {Array.from({ length: totalSlides }).map((_, index) => (
            <ProgressDot 
              key={index} 
              active={currentSlide === index} 
              onClick={() => handleManualSlideChange(index)}
              aria-label={`Go to slide ${index + 1}`}
              type="button"
            />
          ))}
        </ProgressDots>
        <ButtonGroup>
          <SkipButton onClick={dismissModal} type="button">Skip</SkipButton>
          <NextButton onClick={goToNextSlide} disabled={isAnimating} type="button">
            {getButtonText()}
            <ButtonArrow>&rarr;</ButtonArrow>
          </NextButton>
        </ButtonGroup>
      </SlideControls>
      
      <KeyboardShortcuts>
        <KeyboardKey>←</KeyboardKey> <KeyboardKey>→</KeyboardKey> to navigate &nbsp; <KeyboardKey>Esc</KeyboardKey> to close
      </KeyboardShortcuts>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  width: 100%;
  max-width: 720px;
  margin: auto;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  position: relative;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    min-height: auto;
    border-radius: 8px;
  }
  
  @media (max-height: 700px) {
    min-height: auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 28px;
  color: #9CA3AF;
  cursor: pointer;
  z-index: 10;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #374151;
  }
`;

const SliderContent = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  min-height: 400px;
  
  @media (max-width: 768px) {
    min-height: 350px;
  }
  
  @media (max-width: 480px) {
    min-height: 300px;
  }
`;

const StyledSlide = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  
  &.carousel__slide--visible {
    opacity: 1;
    visibility: visible;
    position: relative;
  }
  
  &.carousel__slide--hidden {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
`;

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 20px;
  height: 100%;

  .slide-title {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 700;
    color: #111827;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .slide-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #4b5563;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 24px;
    max-width: 85%;
  }
  
  @media (max-width: 768px) {
    padding: 30px 24px 20px;
    
    .slide-title {
      font-size: 22px;
      margin-bottom: 12px;
    }
    
    .slide-subtitle {
      font-size: 14px;
      margin-bottom: 20px;
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    padding: 24px 20px 16px;
    
    .slide-title {
      font-size: 20px;
    }
  }
`;

const IllustrationContainer = styled.div`
  margin-bottom: 24px;
  max-width: 200px;
  max-height: 160px;
  transition: transform 0.3s ease;
  background-color: #ebe7ff;
  border-radius: 8px;
  padding: 20px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    max-width: 160px;
    max-height: 120px;
    margin-bottom: 20px;
    padding: 16px;
  }
  
  @media (max-width: 480px) {
    max-width: 140px;
    max-height: 100px;
    margin-bottom: 16px;
    padding: 12px;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  
  @media (max-width: 768px) {
    gap: 16px;
    margin-top: 12px;
  }
  
  @media (max-width: 480px) {
    gap: 12px;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  transition: transform 0.2s ease;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background-color: #f5f3ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  svg {
    width: 20px;
    height: 20px;
  }
  
  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
  
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const FeatureText = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 4px 0;
  
  @media (max-width: 768px) {
    font-size: 15px;
    margin: 0 0 2px 0;
  }
  
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const FeatureDescription = styled.p`
  font-size: 14px;
  line-height: 1.4;
  color: #6b7280;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 13px;
  }
  
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const SlideControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px 16px;
  border-top: 1px solid #f3f4f6;
  
  @media (max-width: 768px) {
    padding: 12px 24px;
  }
  
  @media (max-width: 480px) {
    padding: 10px 20px;
  }
`;

const ProgressDots = styled.div`
  display: flex;
  gap: 8px;
`;

const ProgressDot = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.active ? '#6a5acd' : '#e5e7eb'};
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  
  &:hover {
    background-color: ${props => props.active ? '#6a5acd' : '#d1d5db'};
  }
  
  @media (max-width: 480px) {
    width: 8px;
    height: 8px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  
  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const BaseButton = styled.button`
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    font-size: 13px;
    padding: 8px 14px;
  }
  
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 7px 12px;
  }
`;

const SkipButton = styled(BaseButton)`
  background: transparent;
  color: #6b7280;
  
  &:hover:not(:disabled) {
    background-color: #f3f4f6;
  }
`;

const NextButton = styled(BaseButton)`
  background: #6a5acd;
  color: white;
  box-shadow: 0 2px 4px rgba(106, 90, 205, 0.2);
  padding: 10px 20px;
  
  &:hover:not(:disabled) {
    background: #5d4ec5;
    box-shadow: 0 4px 8px rgba(106, 90, 205, 0.3);
  }
  
  &:active:not(:disabled) {
    transform: translateY(1px);
  }
  
  @media (max-width: 768px) {
    padding: 8px 16px;
  }
  
  @media (max-width: 480px) {
    padding: 7px 14px;
  }
`;

const ButtonArrow = styled.span`
  margin-left: 8px;
  font-size: 16px;
  transition: transform 0.2s ease;
  
  ${NextButton}:hover & {
    transform: translateX(3px);
  }
  
  @media (max-width: 480px) {
    margin-left: 6px;
    font-size: 14px;
  }
`;

const KeyboardShortcuts = styled.div`
  padding: 0 0 12px;
  font-size: 12px;
  color: #9ca3af;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 11px;
    padding-bottom: 10px;
  }
  
  @media (max-width: 480px) {
    font-size: 10px;
    padding-bottom: 8px;
  }
`;

const KeyboardKey = styled.kbd`
  display: inline-block;
  padding: 2px 5px;
  font-size: 11px;
  line-height: 1;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin: 0 2px;
  font-family: monospace;
  
  @media (max-width: 768px) {
    padding: 1px 4px;
    font-size: 10px;
  }
  
  @media (max-width: 480px) {
    padding: 1px 3px;
    font-size: 9px;
  }
`;

export default OnboardingSlider;
