import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

/**
 * Encodes a PIN number into a secure format
 * @param {string} number - The PIN to encode
 * @return {string} Encoded PIN
 */
const generatePin = (number) => {
  if (!number || number.trim() === '') return null
  return btoa(`sizle:${number}`)
}

/**
 * Extracts a PIN number from its encoded format
 * @param {string} value - The encoded PIN
 * @return {string} Decoded PIN or empty string
 */
const extractPin = (value) => {
  if (!value) return ''
  try {
    const match = atob(value).match(/sizle:(.+)/)
    return match && match[1] ? match[1] : ''
  } catch (error) {
    console.error('Error decoding PIN:', error)
    return ''
  }
}

/**
 * PIN Input component for document security
 * Allows users to enable PIN protection and set a 4-6 digit PIN
 */
const PinInputComponent = ({
  label = null,
  isDisabled = false,
  activeLinkPin = false,
  defaultValue = '',
  handleAccessPinChange = () => {},
  toggleAccessPin = () => {},
  ...props
}) => {
  // Extract pin from defaultValue if provided
  const [pin, setPin] = useState(() => {
    try {
      return defaultValue ? extractPin(defaultValue) : ''
    } catch (e) {
      console.error('Invalid default PIN value:', e)
      return ''
    }
  })
  
  const linkInputRef = useRef(null)
  const { t } = useTranslation()
  const [enabled, setEnabled] = useState(!!defaultValue)
  const [isValid, setIsValid] = useState(true)
  const [hasFocus, setHasFocus] = useState(false)
  const id = 'security-pin-input'

  /**
   * Validates if a PIN meets the required format (4-6 digits)
   * @param {string} value - The PIN to validate
   * @return {boolean} Whether the PIN is valid
   */
  const validatePin = useCallback((value) => {
    if (!value || value.trim() === '') {
      // Empty PIN is not valid but don't show error initially
      setIsValid(true)
      return false
    }
    
    if (enabled && value) {
      const isValidPin = /^\d{4,6}$/.test(value)
      setIsValid(isValidPin)
      return isValidPin
    }
    return true
  }, [enabled])

  /**
   * Handles changes to the PIN input
   */
  const handlePinChange = useCallback((e) => {
    const newPin = e.target.value.replace(/\D/g, '').slice(0, 6) // Allow only digits, max 6
    setPin(newPin)
    
    const isValidPin = validatePin(newPin)
    if (enabled) {
      handleAccessPinChange(isValidPin ? generatePin(newPin) : null)
    }
  }, [enabled, validatePin, handleAccessPinChange])

  /**
   * Toggles the PIN protection on/off
   */
  const handleToggle = useCallback((e) => {
    const isEnabled = !enabled
    setEnabled(isEnabled)
    
    // Call toggleAccessPin if provided (for backward compatibility)
    if (typeof toggleAccessPin === 'function') {
      toggleAccessPin(isEnabled)
    }
    
    if (!isEnabled) {
      // When disabling, clear the PIN input for security
      setPin('')
      handleAccessPinChange(null)
      setIsValid(true)
    } else {
      const isValidPin = validatePin(pin)
      if (isValidPin) {
        handleAccessPinChange(generatePin(pin))
      }
      
      // Focus the input field if toggling on and the PIN is empty or invalid
      if ((!pin || !isValidPin) && linkInputRef.current) {
        linkInputRef.current.focus()
      }
    }
  }, [enabled, toggleAccessPin, handleAccessPinChange, validatePin, pin])
  
  // Focus the PIN input field when activeLinkPin becomes true
  useEffect(() => {
    if (activeLinkPin && linkInputRef.current) {
      linkInputRef.current.focus()
    }
  }, [activeLinkPin])

  // Handle Enter key press in PIN input field (submit if valid)
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && enabled) {
      if (validatePin(pin)) {
        e.preventDefault() // Prevent form submission
        handleAccessPinChange(generatePin(pin))
        linkInputRef.current?.blur()
      }
    }
  }, [enabled, pin, validatePin, handleAccessPinChange])

  return (
    <StyledPinInput role="group" aria-labelledby={id}>
      <ToggleButton 
        disabled={isDisabled} 
        type='button' 
        onClick={handleToggle} 
        role="switch"
        aria-checked={enabled}
        data-state={enabled ? "checked" : "unchecked"}
      >
        <ToggleThumb data-state={enabled ? "checked" : "unchecked"} />
      </ToggleButton>
      <LabelText htmlFor={id}>
        {label || t('PIN')}
      </LabelText>
      <InputContainer>
        <StyledInput
          ref={linkInputRef}
          id={id}
          placeholder='eg. 1234'
          type='text'
          name='linkPin'
          value={enabled ? pin : ''}
          disabled={isDisabled || !enabled}
          onChange={handlePinChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          aria-label={t('PIN code for document access')}
          aria-invalid={!isValid}
          aria-describedby={!isValid ? `${id}-error` : undefined}
          maxLength={6}
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="off"
          required={enabled}
        />
        {!isValid && pin && enabled && (
          <ErrorMessage id={`${id}-error`} role="alert">
            {t('Please enter a 4-6 digit PIN')}
          </ErrorMessage>
        )}
        {!pin && hasFocus && enabled && (
          <HintMessage>
            {t('Enter 4-6 digit PIN')}
          </HintMessage>
        )}
      </InputContainer>
    </StyledPinInput>
  )
}

const StyledPinInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  position: relative;
  width: 100%;
  
  /* Ensure proper alignment with security-input parent class */
  .security-input & {
    margin-bottom: 0;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 160px;
`

const LabelText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  line-height: 1.3;
  margin-left: 0;
  margin-right: 12px;
  width: auto;
`

const StyledInput = styled.input`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
  background-color: var(--input-background, #fff);
  color: var(--text-color);
  font-size: 14px;
  height: 36px;
  width: 100%;
  transition: all 0.2s ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
    background-color: var(--input-background-disabled, #f5f5f5);
    border-color: var(--border-color, rgba(0, 0, 0, 0.1));
  }
  
  &:disabled::placeholder {
    color: var(--text-muted, #999);
  }
  
  &:focus {
    outline: none;
    border-color: var(--primary-color, #6a5acd);
    box-shadow: 0 0 0 2px var(--primary-color-faint, rgba(106, 90, 205, 0.25));
  }
  
  &::placeholder {
    color: var(--text-muted, #999);
    opacity: 1;
  }
  
  &:hover:not(:disabled) {
    border-color: var(--border-color-hover, rgba(0, 0, 0, 0.2));
  }
  
  &[aria-invalid="true"] {
    border-color: var(--error-color, #ff5a5a);
    
    &:focus {
      box-shadow: 0 0 0 2px var(--error-color-faint, rgba(255, 90, 90, 0.2));
    }
  }
`

const ToggleButton = styled.button`
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
  border: 1px solid var(--border-color);
  cursor: pointer;
  padding: 0;
  flex-shrink: 0;
  transition: all 0.2s ease;
  
  &[data-state="checked"] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

const ToggleThumb = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &[data-state="checked"] {
    transform: translateX(20px);
  }
`

const ErrorMessage = styled.div`
  color: var(--error-color, #ff5a5a);
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  bottom: -20px;
  left: 0;
`

const HintMessage = styled.div`
  color: var(--text-color-lighter);
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  bottom: -20px;
  left: 0;
`

export default PinInputComponent