import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/core';
// @ts-ignore
import { toastr } from 'react-redux-toastr';

import { sendAmplitudeData } from 'src/utils/amplitude';
import { useModal } from 'src/modules/modals';
import { workspaceContext, useUserContext, presentationContext } from 'src/context';

interface MenuProps {
  title: string;
  presentationId: string;
  closePopup: () => void;
  onClickAnalytics: () => void;
  openTrackingEnabled: boolean;
  emailNotificationsEnabled: boolean;
  triggerRef: React.RefObject<HTMLButtonElement>;
  parentRef: React.RefObject<HTMLDivElement>;
}

const PresentationOptionsPopupMenu: React.FC<MenuProps> = ({
  title,
  presentationId,
  closePopup,
  onClickAnalytics,
  openTrackingEnabled,
  emailNotificationsEnabled,
  triggerRef,
  parentRef
}) => {
  const navigate = useNavigate();
  const { workspace, deleteDocument, renameDocument, showStorageFilledPrompt } = useContext(workspaceContext);
  const { clonePresentation } = useContext(presentationContext);
  const { hasWorkspacePermission } = useContext(workspaceContext);
  const { workspaceIndex } = useUserContext();
  const [renameLoading, setRenameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuReady, setMenuReady] = useState(false);
  const { isOpen: isOpenRename, onOpen: onOpenRename, onClose: onCloseRename } = useDisclosure();
  const { setModal } = useModal();
  const { t } = useTranslation();

  // Position the menu directly inside the parent card, relative to the trigger button
  const positionMenu = useCallback(() => {
    if (!menuRef.current || !triggerRef?.current || !parentRef?.current) {
      return;
    }
    
    try {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const parentRect = parentRef.current.getBoundingClientRect();
      
      // Calculate position relative to the trigger button
      const top = triggerRect.bottom - parentRect.top + 5; // 5px below trigger
      const left = triggerRect.right - parentRect.left - 150; // Align right edge with 30px offset
      
      // Apply position
      menuRef.current.style.top = `${top}px`;
      menuRef.current.style.left = `${left}px`;
      menuRef.current.style.opacity = '1';
      menuRef.current.style.transform = 'scale(1)';
      
      // Position arrow to point at trigger button
      const arrowPosition = (triggerRect.left + triggerRect.width/2) - left - parentRect.left;
      menuRef.current.style.setProperty('--arrow-position', `${Math.min(Math.max(arrowPosition, 20), 160)}px`);
      
      setMenuReady(true);
    } catch (error) {
      console.error('Error positioning menu:', error);
    }
  }, [triggerRef, parentRef]);

  // Add a global click handler to close the menu when clicking outside
  useEffect(() => {
    const handleGlobalClick = (e: MouseEvent) => {
      // Skip if we're in rename mode
      if (isOpenRename) return;
      
      // If the menu exists and click is outside the menu and not on the trigger button
      if (
        menuRef.current && 
        !menuRef.current.contains(e.target as Node) && 
        triggerRef.current && 
        !triggerRef.current.contains(e.target as Node)
      ) {
        closePopup();
      }
    };
    
    document.addEventListener('click', handleGlobalClick);
    return () => document.removeEventListener('click', handleGlobalClick);
  }, [closePopup, triggerRef, isOpenRename]);

  // Position menu on mount and window resize
  useEffect(() => {
    // Initial positioning with delay to ensure DOM is ready
    const timer = setTimeout(() => {
      positionMenu();
    }, 10);
    
    // Reposition on window resize
    window.addEventListener('resize', positionMenu);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', positionMenu);
    };
  }, [positionMenu]);

  // Stop event propagation to prevent card interactions
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleCopyButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Close popup first to prevent any UI issues
    closePopup();
    
    // Execute with slight delay to ensure popup is closed
    setTimeout(async () => {
      try {
        if (workspace.isOverStorage || workspace.isOverFileLimit) {
          showStorageFilledPrompt();
        } else {
          sendAmplitudeData('DUPLICATING_DOCUMENT', { presentationId });
          await clonePresentation(presentationId);
          toastr.success(t('Document duplicated successfully'));
        }
      } catch (error) {
        console.error('Error duplicating presentation:', error);
        toastr.error(t('Error duplicating presentation'));
      }
    }, 50);
  };

  const handleShareClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Close popup first
    closePopup();
    
    // Execute with slight delay
    setTimeout(() => {
      sendAmplitudeData('OPENING_SHARE_MODAL');
      setModal('share_presentation', {
        title,
        presentationId,
        openTrackingEnabled,
        emailNotificationsEnabled,
      });
    }, 50);
  };

  const handleAnalyticsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Close popup first
    closePopup();
    
    // Execute with slight delay
    setTimeout(() => {
      onClickAnalytics();
    }, 50);
  };

  const handlePreviewClick = (e: React.MouseEvent) => {
    // For links, we need a different approach since we want navigation to work
    e.stopPropagation();
    
    // Close popup first
    closePopup();
    
    // Use navigate instead of Link for better control
    setTimeout(() => {
      navigate(`/s/${workspaceIndex}/presentation/${presentationId}/edit`);
    }, 50);
    
    // Prevent default to handle navigation manually
    e.preventDefault();
  };
  
  const handleRenameClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Just open rename input in place
    onOpenRename();
  };

  const updateTitle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    const formData = new FormData(e.currentTarget);
    const newTitle = formData.get('presentationTitle')?.toString();
    if (!newTitle?.length) return;

    setRenameLoading(true);
    try {
      await renameDocument(presentationId, newTitle);
      toastr.success(t('Document renamed'));
    } catch (error) {
      toastr.error(t('Error renaming document'));
      console.error(error);
    } finally {
      setRenameLoading(false);
      onCloseRename();
      closePopup();
    }
  };

  const handleDeleteButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Close popup first to avoid UI issues
    closePopup();
    
    // Execute with slight delay
    setTimeout(async () => {
      const userConfirmed = window.confirm(t('Are you sure you want to delete this item?'));
      if (userConfirmed) {
        try {
          setDeleteLoading(true);
          await deleteDocument(presentationId);
          toastr.success(t('File has been deleted'));
        } catch (error) {
          toastr.error(t('Error deleting file'));
          console.error(error);
        } finally {
          setDeleteLoading(false);
        }
      }
    }, 50);
  };

  return (
    <MenuContainer 
      ref={menuRef} 
      isReady={menuReady} 
      onClick={stopPropagation}
      data-testid="options-popup-menu"
    >
      <MenuContent>
        {hasWorkspacePermission('Share Documents') && (
          <MenuItem 
            onMouseDown={handleShareClick}
            data-action="share"
            role="button"
          >
            {t('Share')}
          </MenuItem>
        )}
        {hasWorkspacePermission('Analytics & Reports') && (
          <MenuItem 
            onMouseDown={handleAnalyticsClick}
            data-action="leads"
            role="button"
          >
            {t('Leads')}
          </MenuItem>
        )}
        {hasWorkspacePermission('Add & Edit Documents') && (
          <>
            <MenuItem 
              onMouseDown={handlePreviewClick}
              data-action="preview"
              role="button"
            >
              {t('Preview')}
            </MenuItem>

            <MenuItem 
              onMouseDown={handleRenameClick}
              data-action="rename"
              role="button"
            >
              {t('Rename')}
            </MenuItem>
            {isOpenRename && (
              <RenameForm onSubmit={updateTitle}>
                <input
                  className={renameLoading ? 'loading-spinner' : ''}
                  name="presentationTitle"
                  defaultValue={title}
                  autoFocus
                  onClick={stopPropagation}
                />
              </RenameForm>
            )}

            <MenuItem 
              onMouseDown={handleCopyButtonClick}
              data-action="duplicate"
              role="button"
            >
              {t('Duplicate')}
            </MenuItem>

            <MenuItem
              className="delete-action"
              onMouseDown={handleDeleteButtonClick}
              data-action="delete"
              role="button"
            >
              {t('Delete')}
            </MenuItem>
          </>
        )}
      </MenuContent>
    </MenuContainer>
  );
};

interface MenuContainerProps {
  isReady: boolean;
}

const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;
  background-color: var(--background-color);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  box-sizing: border-box;
  opacity: 0;
  transform: scale(0.95);
  transform-origin: top right;
  transition: opacity 0.2s ease, transform 0.2s ease;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(10px);
  pointer-events: ${props => props.isReady ? 'auto' : 'none'};
  overflow: visible;
  
  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: var(--arrow-position, 90%);
    transform: translateX(-50%) rotate(45deg);
    width: 12px;
    height: 12px;
    background-color: var(--background-color);
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    z-index: 0;
  }
`;

const MenuContent = styled.div`
  padding: 6px;
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  z-index: 1;
`;

const MenuItem = styled.div`
  background: none;
  font-size: 0.85rem;
  border: none;
  color: var(--text-color);
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 10;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: var(--background-hover-color);
    transform: translateX(2px);
    color: var(--primary-color);
  }
  
  &:active {
    background-color: var(--background-active-color);
    transform: translateX(2px) scale(0.98);
  }

  &.delete-action {
    color: var(--error-color);
    margin-top: 4px;
    padding-top: 12px;
    border-top: 1px solid var(--border-color);
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    
    &:hover {
      background-color: var(--error-color-faint);
      color: var(--error-color);
    }
  }
`;

const RenameForm = styled.form`
  margin: 4px 8px;
  position: relative;
  z-index: 20;
  
  input {
    width: 100%;
    padding: 8px 10px;
    border: 2px solid var(--primary-color);
    border-radius: 6px;
    color: var(--text-color);
    background-color: var(--input-background-color);
    font-size: 14px;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px var(--primary-color-faint);
    }
  }
`;

export default PresentationOptionsPopupMenu;