import React, { useContext, useEffect, useState, useRef } from 'react'
import { format, fromUnixTime } from 'date-fns'
import BeatLoader from 'react-spinners/BeatLoader'
import { toastr } from 'react-redux-toastr'
import { BaseInput } from 'src/design-system/elements'
import styled from '@emotion/styled'
import { FaKey } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { subscriptionContext } from 'src/context'
import { Pane, SubscriptionForm } from 'src/modules/billing'
import { FAQSection } from 'src/modules/billing/faq-section'
import { PaymentMethodForm } from 'src/modules/billing/PaymentMethod'
import { FiRefreshCw } from 'react-icons/fi'

const BillingPage = () => {
  const { 
    cancelSubscription, 
    soloMonthlyPlan, 
    soloAnnualPlan, 
    advMonthlyPlan, 
    advAnnualPlan, 
    monthlyPlan, 
    annualPlan, 
    invoices, 
    redeemLicenseKey, 
    nextInvoiceDate, 
    nextInvoiceAmount, 
    subscriptionCadence, 
    currentPlan,
    hasPro,
    subscriptionError,
    refetchSubscription
  } = useContext(subscriptionContext)
  const { t } = useTranslation()
  const [licenseKey, setLicenseKey] = useState('')
  const [submittingLicenseKey, setSubmittingLicenseKey] = useState(false)
  const [upgradingPlan, setUpgradingPlan] = useState(null)
  const [cancellingSubscription, setCancellingSubscription] = useState(false)
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false)
  const [viewingPlan, setViewingPlan] = useState(null)
  const [cadence, setCadence] = useState('monthly')
  const [isRefreshing, setIsRefreshing] = useState(false)
  const navigate = useNavigate()
  const { hash } = useLocation()
  const formRef = useRef(null)

  useEffect(() => {
    if (hash === '#update-payment') {
      setTimeout(window.requestAnimationFrame(() => {
        formRef.current.scrollIntoView({ behavior: 'smooth' })
        navigate('#')
      }), 0)
    }
  }, [hash, navigate])

  useEffect(() => {
    sendAmplitudeData('PAGE_VIEW_BILLING')
  }, [])

  const handleRefreshBillingData = async () => {
    if (isRefreshing) return;
    
    setIsRefreshing(true);
    try {
      await refetchSubscription();
      toastr.success('Billing data refreshed', 'The latest billing information has been loaded');
    } catch (error) {
      toastr.error('Error refreshing data', 'Please try again later');
    } finally {
      setIsRefreshing(false);
    }
  };

  const submitLicenseKey = async () => {
    setSubmittingLicenseKey(true)
    try {
      await redeemLicenseKey(licenseKey)
      toastr.info('License key activated')
    } catch (e) {
      toastr.error('There was an issue while activating your license key, please contact support.')
      throw e
    }
    setSubmittingLicenseKey(false)
  }

  const handleCancelPlan = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    setCancellingSubscription(true)
    try {
      await cancelSubscription()
      toastr.info('Your account has been downgraded to a Free account')
    } catch (e) {
      toastr.error('There was an issue while downgrading your account, please contact support.')
      throw e
    }
    setCancellingSubscription(false)
    setViewingPlan(null)
    setConfirmCancelPopup(false)
  }

  const renderErrorBanner = () => {
    if (!subscriptionError) return null;
    
    return (
      <div className="billing-error-banner">
        <div className="message">
          <h3>Billing information unavailable</h3>
          <p>We're having trouble retrieving your subscription details. Some pricing information may be inaccurate.</p>
        </div>
        <button 
          className="retry-button" 
          onClick={handleRefreshBillingData}
          disabled={isRefreshing}
        >
          <FiRefreshCw className={isRefreshing ? 'rotating' : ''} />
          {isRefreshing ? 'Refreshing...' : 'Refresh'}
        </button>
      </div>
    );
  };

  return (
    <div className='page-container billing-page'>
      <div className="page-header">
        <h1>Billing</h1>
      </div>

      <div className="billing-content">
        {renderErrorBanner()}
        
        {currentPlan && (
          <div className="plan-description">
            {t('You are currently on a')}{' '}
            <span className="highlight">{currentPlan?.planTier}{' '}</span>
            {currentPlan?.planType?.toLowerCase()}
          </div>
        )}

        <div className='cadence-selector'>
          <span className={`${cadence === 'monthly' ? 'active' : ''}`}>
            <h5>{t('Monthly plan')}</h5>
          </span>
          <button 
            onClick={() => setCadence(c => c === 'monthly' ? 'annual' : 'monthly')} 
            className={`switch ${cadence === 'annual' ? 'on' : ''}`} 
            aria-label="Toggle annual billing"
          />
          <span className={`${cadence === 'annual' ? 'active' : ''}`}>
            <h5>Annual plan (Save 30%)</h5>
          </span>
        </div>

        <p>Choose a plan below. You can change or cancel anytime.</p>

        <div className='pane-container'>
          <Pane 
            viewingPlan={viewingPlan} 
            cancel={() => setConfirmCancelPopup(true)} 
            activate={setUpgradingPlan} 
            plan={cadence === 'monthly' ? soloMonthlyPlan : soloAnnualPlan} 
          />
          <Pane 
            viewingPlan={viewingPlan} 
            cancel={() => setConfirmCancelPopup(true)} 
            activate={setUpgradingPlan} 
            plan={cadence === 'monthly' ? monthlyPlan : annualPlan} 
          />
          <Pane 
            viewingPlan={viewingPlan} 
            cancel={() => setConfirmCancelPopup(true)} 
            activate={setUpgradingPlan} 
            plan={cadence === 'monthly' ? advMonthlyPlan : advAnnualPlan} 
          />
        </div>

        <h2>Compare plan features</h2>
        <div className="comparison-table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Solo</th>
                <th>Pro</th>
                <th>Advanced</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Workspaces</td>
                <td>2</td>
                <td>5</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Storage per Workspace</td>
                <td>25GB</td>
                <td>200GB</td>
                <td>350GB</td>
              </tr>
              <tr>
                <td>Unlimited Viewers</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Document Short URLs</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Document Analytics</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Password-Protected Links</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Download Block</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Document Approvals</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Document Reactions</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Document Comments</td>
                <td>✓</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Link Burn Timer</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Link Expiry</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Custom User Roles</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Unlimited Docs</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Lead capture</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Use Custom Logo</td>
                <td>—</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Priority Support</td>
                <td>—</td>
                <td>—</td>
                <td>✓</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div ref={formRef}>
          <PaymentMethodForm />
        </div>

        {invoices?.length > 0 && (
          <div className="page-section">
            <div className="section-header">
              <h4>Invoices</h4>
            </div>
            <div className="section-content">
              <div className="invoice-list">
                {invoices.map((invoice) => (
                  <div className="invoice-item" key={invoice.created}>
                    <div className="invoice-date">{format(fromUnixTime(invoice.created), 'do MMM yyyy')}</div>
                    <div className="invoice-actions">
                      <a href={invoice.invoiceUrl} className="btn secondary" target="_blank" rel="noopener noreferrer">
                        {t('View Invoice')}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {nextInvoiceAmount && (
          <div className="next-payment-info">
            {t('Your next')}{' '}
            <span className="highlight">{subscriptionCadence}{' '}</span>
            {t('payment of')}{' '}
            <span className="highlight">${nextInvoiceAmount}{' '}</span>
            {t('will be due on')}{' '}
            <span className="highlight">{nextInvoiceDate}</span>
          </div>
        )}
      </div>

      {upgradingPlan && upgradingPlan.id !== currentPlan?.planId && (
        <div className="modal-overlay" onClick={() => setUpgradingPlan(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SubscriptionForm 
              onComplete={() => setUpgradingPlan(null)} 
              cancel={() => setUpgradingPlan(null)} 
              plan={upgradingPlan} 
            />
          </div>
        </div>
      )}

      {confirmCancelPopup && (
        <div className="modal-overlay" onClick={() => setConfirmCancelPopup(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{t('Are you sure?')}</h2>
            <p>
              {t('You are cancelling your')}{' '}
              {currentPlan?.billingCadence}{' '}
              {t('plan as well as any additional paid workspace seats, effective immediately')}
            </p>
            <div className="modal-actions">
              <button className="btn secondary" onClick={() => setConfirmCancelPopup(false)}>
                {t('Go back')}
              </button>
              <button 
                className="btn danger" 
                onClick={handleCancelPlan} 
                disabled={cancellingSubscription}
              >
                {cancellingSubscription ? <BeatLoader size={8} color="#fff" /> : t('Cancel Subscription')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BillingPage;
