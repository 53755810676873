import React, { useState, createContext, useEffect } from 'react'

const ModeContext = createContext()

const ModeProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('sizle_mode')
    return savedMode || 'dark'
  })
  const [hideSizleBrand, setHideSizleBrand] = useState(() => {
    const savedHideSizleBrand = localStorage.getItem('sizle_hide_brand')
    return savedHideSizleBrand === 'true'
  })

  useEffect(() => {
    // Store in both localStorage keys for compatibility
    localStorage.setItem('sizle_mode', mode)
    localStorage.setItem('theme', mode) // Also update theme for ThemeContext
    
    // Update document elements
    document.documentElement.setAttribute('data-theme', mode)
    
    // Update document classes
    document.documentElement.classList.remove(mode === 'dark' ? 'light' : 'dark')
    document.documentElement.classList.add(mode)
    
    // Apply classes for root element as well
    const rootElement = document.getElementById('root')
    if (rootElement) {
      if (mode === 'dark') {
        rootElement.classList.remove('light')
        rootElement.classList.add('dark')
      } else {
        rootElement.classList.remove('dark')
        rootElement.classList.add('light')
      }
    }
    
    // Dispatch an event that ThemeContext can listen to
    window.dispatchEvent(new CustomEvent('mode-changed', { detail: { mode } }))
  }, [mode])

  useEffect(() => {
    localStorage.setItem('sizle_hide_brand', hideSizleBrand)
  }, [hideSizleBrand])

  return (
    <ModeContext.Provider value={{ mode, setMode, hideSizleBrand, setHideSizleBrand }}>
      {children}
    </ModeContext.Provider>
  )
}

export { ModeContext, ModeProvider } 