import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import BeatLoader from 'react-spinners/BeatLoader';
import { useTranslation } from 'react-i18next';

import CustomTable from './CustomTable';
import { ModeContext, useUserContext } from 'src/context';
import DashboardNotice from 'src/modules/presentations/components/DashboardNotice';

const UserPresentations = ({ presentations = [], isLoading, areSidebarsCollapsed }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [presentationsPerPage, setPresentationsPerPage] = useState(15); // Set to 12 presentations per page.
  const containerRef = useRef(null);

  useEffect(() => {
    setTotalPages(Math.ceil(presentations.length / presentationsPerPage));
  }, [presentations.length, presentationsPerPage, areSidebarsCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      setPresentationsPerPage(15); 
    };

    // Update presentation count on resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(presentations.length / presentationsPerPage));
  }, [presentations.length, presentationsPerPage]);

  const indexOfLastPresentation = currentPage * presentationsPerPage;
  const indexOfFirstPresentation = indexOfLastPresentation - presentationsPerPage;
  const currentPresentations = presentations.slice(indexOfFirstPresentation, indexOfLastPresentation);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [activeFilter, setActiveFilter] = useState('Last edited');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { mode } = useContext(ModeContext);
  const { setNotificationView, notificationView } = useUserContext();
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangeFilter = (newFilter) => {
    setActiveFilter(newFilter);
  };

  const filterItems = [
    { name: t('inputs.Last created'), value: 'Last created' },
    { name: t('inputs.Last edited'), value: 'Last edited' },
    { name: t('inputs.Name (A-Z)'), value: 'Name (A-Z)' },
  ];

  const sortedPresentations = currentPresentations
    ? currentPresentations.slice().sort((a, b) =>
        activeFilter === 'Last edited'
          ? new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          : activeFilter === 'Last created'
          ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          : a.title.localeCompare(b.title)
      )
    : [];

  return (
    <PresentationsWrapper ref={containerRef}>
      <PresentationsContainer>
        <CustomTable presentations={sortedPresentations} isLoading={isLoading} />
        {!sortedPresentations.length && !isLoading && <DashboardNotice />}
        {isLoading && <BeatLoader color={mode === 'dark' ? '#fff' : '#333'} />}
        {totalPages > 1 && (
          <Pagination>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            <PaginationInfo>
              Page {currentPage} of {totalPages}
            </PaginationInfo>
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </PaginationButton>
          </Pagination>
        )}
      </PresentationsContainer>
    </PresentationsWrapper>
  );
};

const PresentationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding: 0px 24px;
  position: relative;
  background-color: var(--background-color);
  min-height: calc(100vh - 60px);
`;

const PresentationsContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  position: relative;
  z-index: 0;
`;

const PresentationCard = styled.div`
  display: flex;
  justify-content: space-between; /* Pushes the options button to the right */
  align-items: center; /* Aligns content vertically */
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: var(--card-background-color);
`;

const CardContent = styled.div`
  flex-grow: 1;
`;

const OptionsButton = styled.button`
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  margin-left: auto; /* Ensures the button is at the right edge */
`;

const Pagination = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;
  border-top: 1px solid var(--border-color);
`;

const PaginationButton = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: var(--background-color);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background: var(--background-hover-color);
    border-color: var(--border-color-dark);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: var(--border-color-light);
  }
`;

const PaginationInfo = styled.span`
  color: var(--text-color-muted);
  font-size: 14px;
  font-weight: 500;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
`;

export default UserPresentations;
