import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { workspaceContext } from 'src/context';

export const ProPopup: React.FunctionComponent<{
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  requiredFeature?: string;
  active?: boolean;
}> = ({
  active = false,
  title = 'Upgrade to unlock',
  subtitle = 'Choose a plan to unlock this feature',
  requiredFeature,
  children,
}) => {
  const { currentWorkspacePlan, workspaceIndex } = useContext(workspaceContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Log missing requiredFeature or plan issues
  if (!requiredFeature) {
    console.warn('ProPopup: `requiredFeature` is missing or undefined.');
  }
  if (!currentWorkspacePlan) {
    console.warn('ProPopup: `currentWorkspacePlan` is not defined.');
  }

  // Calculate preventAccess
  // For the "Create new workspace" button, we want to be able to click it to show the upgrade dialog
  // rather than completely blocking access
  let preventAccess = false;
  
  if (requiredFeature && currentWorkspacePlan) {
    // Normal feature access check
    preventAccess = !currentWorkspacePlan[requiredFeature];
  } else if (active && isClickablePromotion()) {
    // Special case for Create new workspace - allow the button to be clicked
    preventAccess = false;
  } else {
    // Default to the active prop
    preventAccess = active;
  }

  // Special detection for "Create new workspace" button
  function isClickablePromotion() {
    // Check for "Create new workspace" button specifically
    const isCreateWorkspaceButton = 
      React.Children.count(children) === 1 && 
      React.isValidElement(React.Children.toArray(children)[0]) &&
      (React.Children.toArray(children)[0] as React.ReactElement).props?.className?.includes('create-workspace');
    
    return isCreateWorkspaceButton;
  }

  console.log('ProPopup Debug:', {
    requiredFeature,
    active,
    currentWorkspacePlan,
    preventAccess,
    isCreateWorkspaceButton: isClickablePromotion()
  });

  // Handlers
  const closePopup = useCallback(() => setIsOpen(false), []);
  const goToBillingAndClose = useCallback(() => {
    setIsOpen(false);
    navigate(`/s/${workspaceIndex}/billing`);
  }, [navigate, workspaceIndex]);
  const openPopup = useCallback(() => {
    setIsOpen(true);
  }, []);

  // Allow click-through for "Create new workspace" button
  if (active && isClickablePromotion()) {
    return (
      <div className='propopup'>
        {/* Make the children clickable to open the popup */}
        <div className="clickable-promotion" onClick={openPopup}>
          {children}
        </div>
        
        {isOpen && (
          <div
            className='modal-container'
            onClick={closePopup}
            role='dialog'
            aria-labelledby='propopup-title'
            aria-describedby='propopup-subtitle'
          >
            <div onClick={(e) => e.stopPropagation()} className='modal'>
              <h1 id='propopup-title'>{title}</h1>
              <h2 id='propopup-subtitle'>{subtitle}</h2>
              <div className='propopup-buttons'>
                <button
                  className='btn primary'
                  onClick={goToBillingAndClose}
                  aria-label='View plans'
                >
                  View plans
                </button>
                <button
                  onClick={closePopup}
                  className='btn secondary'
                  aria-label='Maybe later'
                >
                  Maybe later
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='propopup'>
      {children}
      {isOpen && (
        <div
          className='modal-container'
          onClick={closePopup}
          role='dialog'
          aria-labelledby='propopup-title'
          aria-describedby='propopup-subtitle'
        >
          <div onClick={(e) => e.stopPropagation()} className='modal'>
            <h1 id='propopup-title'>{title}</h1>
            <h2 id='propopup-subtitle'>{subtitle}</h2>
            <div className='propopup-buttons'>
              <button
                className='btn primary'
                onClick={goToBillingAndClose}
                aria-label='View plans'
              >
                View plans
              </button>
              <button
                onClick={closePopup}
                className='btn secondary'
                aria-label='Maybe later'
              >
                Maybe later
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
