import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { humanFileSize } from 'src/utils'
import { subscriptionContext } from 'src/context'

const Pane = ({ plan, cancel, activate }: {viewingPlan: any, plan: any, cancel: any, activate: any}) => {
  const { t } = useTranslation()
  const { currentPlan } = useContext(subscriptionContext)
  const planTier = (plan?.planTier || '').toLowerCase() || 'free'
  const currentPlanType = (currentPlan?.planType || '').toLowerCase() || 'plan'
  const currentPlanTier = (currentPlan?.planTier || '').toLowerCase() || 'expired solo'

  const calcOrder = (planTier: string) => {
    switch (planTier.toLowerCase()) {
      case 'free':
      case 'trial':
        return 0
      case 'pro':
        return 1
      case 'advanced':
        return 2
      default:
        return 0
    }
  }

  const btnLabel =
    (currentPlanType === 'trial' || currentPlanTier === 'expired solo')
      ? 'Upgrade'
      : currentPlan.planId === plan.id
        ? 'Cancel'
        : (calcOrder(planTier) - calcOrder(currentPlan.planTier)) < 0
          ? 'Downgrade'
          : (calcOrder(planTier) - calcOrder(currentPlan.planTier)) === 0
            ? 'Switch'
            : (calcOrder(planTier) - calcOrder(currentPlan.planTier)) > 0
              ? 'Upgrade'
              : 'Unk'

  const onActivateClick = useCallback(() => {
    if (currentPlan.planId === plan.id) {
      cancel && cancel(plan)
    } else {
      activate && activate(plan)
    }
  }, [activate, cancel, currentPlan.planId, plan])

  // Calculate price with fallback for invalid cost data
  const displayPrice = () => {
    // Check if plan and cost exist and are valid numbers
    if (!plan || plan.cost === undefined || plan.cost === null || isNaN(plan.cost)) {
      return "Contact sales"; // Fallback text when cost is invalid
    }
    
    try {
      const costPerMonth = (plan.cost / 100) / (plan.billingCadence === 'annual' ? 12 : 1);
      // Check if the calculation results in a valid number
      if (isNaN(costPerMonth) || !isFinite(costPerMonth)) {
        return "Contact sales";
      }
      return costPerMonth.toFixed(2);
    } catch (e) {
      console.error("Error calculating price:", e);
      return "Contact sales";
    }
  };

  return (
    <div className='billing-pane'>
      <div className='plan-name'>{plan?.planTier || 'Plan'}</div>
      <div className='cost'>
        <span className='unit'>
          {displayPrice() === "Contact sales" ? displayPrice() : `$${displayPrice()}`}
        </span>
        {displayPrice() !== "Contact sales" && <span className='period'> per month</span>}
      </div>

      <div className='summary'>{t(`billing.pane.summary.${planTier}`)}</div>
      <div className='feature-list'>
        {(t(`billing.pane.features.${planTier}`, {
          workspaceLimit: plan?.workspaceCount === 99 ? 'Unlimited' : plan?.workspaceCount || '-',
          documentLimit: plan?.documentLimit ? plan.documentLimit : 'Unlimited',
          storageLimit: plan?.storageLimit ? humanFileSize(plan?.storageLimit) : 'Unlimited',
          returnObjects: true
        }) as []).map((f: any, i: any) => <div className='feature' key={i}>{f}</div>)}
      </div>
      <button onClick={onActivateClick} className={`btn primary plan-${planTier}`}>{btnLabel}</button>
    </div>
  )
}

export default Pane
