import React, { useEffect, ReactNode, useMemo, useCallback, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { customAlphabet } from 'nanoid'

import {
  GET_USER_DETAILS,
  UPDATE_WORKSPACE_NAME,
  DELETE_WORKSPACE_MEMBER,
  CREATE_INVITATION_LINK,
  DELETE_INVITATION_LINK,
  UPDATE_INVITATION_LINK
} from 'src/graphql/workspaces'
import { CREATE_FOLDER_QUERY, GET_FOLDERS_QUERY } from 'src/graphql/folders'
import { DELETE_WORKSPACE_INVITE } from 'src/graphql/workspace-invites'
import { getUserType } from 'src/utils/user'
import { subscriptionContext } from './SubscriptionContext'
import { ADD_WORKSPACE_EVENT } from 'src/graphql/workspace-events'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { userContext } from './UserContext'
import { OrganisationProvider } from '.'
import { useModal } from 'src/modules/modals'
import { toastr } from 'react-redux-toastr'

type Permission = 'Workspace Settings' | 'Manage Folders' | 'Add & Edit Documents' | 'Delete Documents' | 'Analytics & Reports' | 'Share Documents' | 'Manage Contacts'

type WorkspaceContext = {
  workspaces: any[],
  folders: any[],
  workspaceIndex: (null | number),
  currentWorkspaceSeatPlan: null,
  permissions: any[],
  workspacePermissions: Permission[],
  currentWorkspaceRoles: any[],
  currentWorkspacePlan: null,
  addCustomDomain: (domain: string) => Promise<void>,
  createWorkspaceRole: (roleName: string) => Promise<void>,
  hasWorkspacePermission: (permission: Permission) => boolean,
  addRolePermission: (role: string, permission: Permission) => Promise<void>,
  removeRolePermission: (role: string, permission: Permission) => Promise<void>,
  customDomains: string[],
  deleteCustomDomain: (url: string) => Promise<void>,
  toggleCustomDomain: (url: string, enabled: boolean) => Promise<void>,
  updateWorkspaceAvatar: (file: File) => Promise<void>,
  updateWorkspaceName: (name: string) => Promise<void>,
  renameDocument: (documentId: string, title: string) => Promise<void>,
  deleteDocument: (documentId: string) => Promise<void>,
  invites: any[]
}

const defaultWorkspaceContext: WorkspaceContext & {[key: string]: any} = {
  workspace: null,
  workspaces: [],
  folders: [],
  workspaceIndex: null,
  currentWorkspaceSeatPlan: null,
  permissions: [],
  workspacePermissions: [],
  currentWorkspaceRoles: [],
  currentWorkspacePlan: null,
  addCustomDomain: async () => {},
  createWorkspaceRole: async () => {},
  hasWorkspacePermission: () => { return false },
  addRolePermission: async () => {},
  removeRolePermission: async () => {},
  customDomains: [],
  deleteCustomDomain: async () => {},
  toggleCustomDomain: async () => {},
  updateWorkspaceAvatar: async () => {},
  updateWorkspaceName: async () => {},
  renameDocument: async () => {},
  deleteDocument: async () => {},
  invites: []
}

export const workspaceContext = React.createContext(defaultWorkspaceContext)

export const WorkspaceProvider = ({ children }: {children: ReactNode | ReactNode[]}) => {
  const [workspaceIdentifier, setWorkspaceIdentifier] = useState(null)
  const { user, activeFolder, setUserWorkspaces, setCurrentWorkspace: setCurrentUserWorkspace } = useContext(userContext)
  const workspaceIndex = workspaceIdentifier ? parseInt(workspaceIdentifier) : 0
  const { purchaseWorkspaceSubsription, tryExtendTrial, trialPlan, seatPlan, freePlan } = useContext(subscriptionContext)
  const [workspaces, setWorkspaces] = useState<any[]>([])
  const [presentations, setPresentations] = useState<Array<any>>([])
  const [currentWorkspace, setCurrentWorkspace] = useState<any>(null)
  const refetchQueries = { refetchQueries: [{ query: GET_USER_DETAILS, variables: { email: user?.email, userId: user?.sub, activeFolder } }] }
  const { refetch: refetchWorkspaces, loading: workspacesLoading, data: getWorkspacesResult } = useQuery(GET_USER_DETAILS, { variables: { userId: user?.sub, email: user?.email, activeFolder } })
  const [updateWorkspaceNameGql] = useMutation(UPDATE_WORKSPACE_NAME, refetchQueries)
  const [removeInviteGql] = useMutation(DELETE_WORKSPACE_INVITE)
  const [removeUserGql] = useMutation(DELETE_WORKSPACE_MEMBER, refetchQueries)
  const [addWorkspaceEvent] = useMutation(ADD_WORKSPACE_EVENT)
  const [addFolderMtn] = useMutation(CREATE_FOLDER_QUERY)
  const [getFoldersQuery, { data: folders, refetch: refetchFolders }] = useLazyQuery(GET_FOLDERS_QUERY)
  useEffect(() => currentWorkspace?.workspace_id && getFoldersQuery({ variables: { workspaceId: currentWorkspace?.workspace_id } }), [getFoldersQuery, currentWorkspace?.workspace_id])
  const [createLink] = useMutation(CREATE_INVITATION_LINK)
  const [deleteLink] = useMutation(DELETE_INVITATION_LINK)
  const [updateLink] = useMutation(UPDATE_INVITATION_LINK)
  const [storageLimitExceededPromptVisible, toggleStorageLimitExceededPrompt] = useState(false)
  const permissions = useMemo(() => getWorkspacesResult?.permissions || [], [getWorkspacesResult])
  const userPermissions = useMemo(() => ((currentWorkspace?.workspace_members?.find((wm: any) => wm.user_id === user?.sub)?.role?.permissions || []).map((p: any) => p.permission)), [user, currentWorkspace])
  const invites = useMemo(() => getWorkspacesResult?.workspace_invites || [], [getWorkspacesResult])
  const { setModal } = useModal()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const updateWorkspaceAvatar = async (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)
    await axios(`/api/workspaces/${currentWorkspace.workspace_id}/avatar`, {
      method: 'POST',
      data: formData
    })
    await refetchWorkspaces()
  }

  const updateWorkspaceName = async (name: string) => {
    if (user?.sub) {
      await updateWorkspaceNameGql({
        variables: { userId: user.sub, workspaceId: currentWorkspace.workspace_id, workspaceName: name }
      })
    } else {
      throw new Error('Attempt to update workspace name before user is authed')
    }
  }

  const removeInvite = async (inviteId: string) => {
    await removeInviteGql({
      variables: { inviteId }
    })
    await refetchWorkspaces()
  }

  const removeUser = async (userId: string) => {
    await removeUserGql({
      variables: { userId, workspaceId: currentWorkspace.workspace_id }
    })
    addWorkspaceEvent({
      variables: {
        workspace_id: currentWorkspace.workspace_id,
        type: 'remove',
        info: { userId }
      }
    })
  }

  const acceptInvite = async (inviteId: string, accepted: boolean) => {
    const invite = invites.find((i: any) => i.invite_id === inviteId)
    await axios.post('/api/self/invites', {
      inviteId,
      emailAddress: user?.email,
      accepted
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    sendAmplitudeData('USER_JOINED_WORKSPACE')
    addWorkspaceEvent({
      variables: {
        workspace_id: invite.workspace_id,
        type: 'join',
        info: {
          userId: user?.sub
        }
      }
    })
    await refetchWorkspaces()
  }

  const inviteUser = async (email: string, workspaceId = currentWorkspace.workspace_id) => {
    await axios.post(`/api/workspaces/${workspaceId}/invites`, { email }, { withCredentials: true })
    addWorkspaceEvent({
      variables: {
        workspace_id: workspaceId || currentWorkspace.workspace_id,
        type: 'invite',
        info: {
          email
        }
      }
    })
    await tryExtendTrial('InviteTeamMember')
    await refetchWorkspaces()
  }

  const updateWorkspaceSeats = useCallback(async (seatCount: number) => {
    await purchaseWorkspaceSubsription(currentWorkspace?.workspace_id, seatCount)
    await refetchWorkspaces({ userId: user?.sub })
  }, [currentWorkspace?.workspace_id, purchaseWorkspaceSubsription, refetchWorkspaces, user?.sub])

  const previewWorkspaceSeatCost = async (seatCount: number) => {
    const res = await axios.get(`/api/workspaces/${currentWorkspace.workspace_id}/subscription?seats=${seatCount}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return res.data
  }

  const joinWorkspace = async (invitationLinkId: string) => {
    const result = await axios.post(`/api/invites/${invitationLinkId}/join`, {}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    await refetchWorkspaces({ userId: user?.sub })
    return result.data
  }

  const createInvitationLink = async () => {
    await createLink({
      variables: {
        workspaceId: currentWorkspace.workspace_id,
        invitationLinkId: customAlphabet('123456789abcdefgh', 10)()
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const deleteInvitationLink = async (invitationLinkId: string) => {
    await deleteLink({
      variables: {
        invitationLinkId
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const updateLinkExtensions = async (invitationLinkId: string, emailExtensions: string[]) => {
    await updateLink({
      variables: {
        invitationLinkId,
        emailExtensions
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const addFolder = async (folderName: string, parentId = null) => {
    console.log('addFolder called with:', { folderName, parentId, workspaceId: currentWorkspace?.workspace_id });
    
    if (!currentWorkspace?.workspace_id) {
      console.error('Cannot create folder: workspace_id is not available');
      throw new Error('Workspace not available. Please refresh the page and try again.');
    }
    
    // Check if we're already in a rate-limited state
    const workspaceKey = `/api/workspaces/${currentWorkspace.workspace_id}`;
    const graphqlKey = '/graphql';
    
    const isRateLimited = 
      localStorage.getItem(`rate_limited_${workspaceKey}`) === 'true' ||
      localStorage.getItem(`rate_limited_${graphqlKey}`) === 'true';
      
    if (isRateLimited) {
      const retryAfter = parseInt(localStorage.getItem(`rate_limit_retry_${graphqlKey}`) || '0', 10);
      const remainingTime = Math.max(0, retryAfter - Date.now());
      if (remainingTime > 0) {
        const seconds = Math.ceil(remainingTime / 1000);
        throw new Error(`Service is busy. Please try again in ${seconds} seconds.`);
      }
    }
    
    try {
      const res = await addFolderMtn({ 
        variables: { 
          folderName, 
          workspaceId: currentWorkspace.workspace_id, 
          parentId,
          isOriginal: false
        } 
      });
      
      console.log('addFolder mutation response:', res);
      
      if (res.errors) {
        console.error('GraphQL errors in addFolder:', res.errors);
        throw new Error(res.errors[0]?.message || 'Failed to create folder due to GraphQL error');
      }
      
      if (!res.data?.insert_folders?.returning?.[0]) {
        console.error('Invalid folder creation response:', res);
        throw new Error('Folder creation failed: Invalid response from server');
      }
      
      // Refetch folders to update the UI
      await refetchFolders({ workspaceId: currentWorkspace.workspace_id });
      
      // Clear any rate limit flags for this workspace
      localStorage.removeItem(`rate_limited_${workspaceKey}`);
      localStorage.removeItem(`rate_limited_${graphqlKey}`);
      
      // Log success
      console.log('Folder created successfully:', res.data.insert_folders.returning[0]);
      
      return res.data.insert_folders.returning[0];
    } catch (error: any) {
      console.error('Error in addFolder:', error);
      
      // Handle rate limiting explicitly
      if (error.networkError?.statusCode === 429 || 
          error.message?.includes('429') || 
          error.message?.toLowerCase().includes('rate limit')) {
        // Set a rate limit flag with expiry
        const retryAfter = 30000; // Default 30s if no header
        const resetTime = Date.now() + retryAfter;
        
        localStorage.setItem(`rate_limited_${graphqlKey}`, 'true');
        localStorage.setItem(`rate_limit_retry_${graphqlKey}`, resetTime.toString());
        
        // Schedule cleanup
        setTimeout(() => {
          localStorage.removeItem(`rate_limited_${graphqlKey}`);
          localStorage.removeItem(`rate_limit_retry_${graphqlKey}`);
        }, retryAfter);
        
        // Dispatch the rate limit event
        window.dispatchEvent(new CustomEvent('rate-limit-detected', { 
          detail: { status: 429, url: '/graphql', isApi: true }
        }));
        
        throw new Error('Service is busy. Please try again later.');
      }
      
      // Try to extract a meaningful error message
      const errorMessage = 
        (error as any).graphQLErrors?.[0]?.message || 
        (error as any).networkError?.result?.errors?.[0]?.message ||
        error.message ||
        'Unknown error creating folder';
        
      throw new Error(`Failed to create folder: ${errorMessage}`);
    }
  }

  useEffect(() => {
    if (workspaceIndex > 0 && !workspaces[workspaceIndex]) {
      navigate('/s/0/presentations')
    }
    
    // Only update the current workspace if it's changing - avoids unnecessary rerenders
    const nextWorkspace = workspaces[workspaceIndex || 0];
    if (nextWorkspace?.workspace_id !== currentWorkspace?.workspace_id) {
      console.log('Setting current workspace:', nextWorkspace?.workspace_id);
      setCurrentWorkspace(nextWorkspace);
    }
  }, [workspaceIndex, workspaces, currentWorkspace?.workspace_id]);

  useEffect(() => {
    if (currentWorkspace?.workspace_id && !folders?.folders) {
      console.log(`Fetching folders for workspace: ${currentWorkspace.workspace_id}`);
      try {
        getFoldersQuery({ 
          variables: { workspaceId: currentWorkspace.workspace_id },
          fetchPolicy: 'network-only' // Force network request to avoid stale cache
        });
      } catch (err) {
        console.error('Error fetching folders:', err);
      }
    }
  }, [getFoldersQuery, currentWorkspace?.workspace_id, folders?.folders]);

  const uploadDocument = useCallback(async (document: File) => {
    setModal('create_document')
    setUploadProgress(0)
    setIsProcessing(false)
    const formData = new FormData()
    formData.append('document', document)
    try {
      // Upload phase
      const response = await axios({
        url: `/api/folder/${activeFolder}/documents`,
        method: 'POST',
        withCredentials: true,
        data: formData,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            // Calculate upload progress as 0-80% of total progress
            const uploadPercentage = (progressEvent.loaded * 80) / progressEvent.total;
            setUploadProgress(Math.min(80, uploadPercentage));
          }
        }
      })
      
      // Set to 80% when upload is complete and processing starts
      setUploadProgress(80);
      setIsProcessing(true);
      
      // Get the presentationId from the response
      const { presentationId } = response.data;
      
      // If we have a successful upload with a presentationId, create a temporary presentation object
      if (presentationId) {
        // Create a temporary presentation object with the necessary fields
        const newPresentation = {
          presentationId,
          title: document.name,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          filesize: document.size,
          originalFileType: document.type.split('/')[1] || 'pdf',
          folderId: activeFolder,
          // Set default values for other fields
          emailNotificationsEnabled: false,
          openTrackingEnabled: false,
          formEnabled: false,
          owner: user ? { auth0_id: user.sub } : null,
        };
        
        // Add to presentations array safely
        if (presentations) {
          setPresentations([newPresentation, ...presentations]);
        }
      }
      
      // Simulate processing progress from 80% to 90%
      const processingInterval = setInterval(() => {
        setUploadProgress(current => {
          if (current < 90) {
            return current + 1;
          }
          clearInterval(processingInterval);
          return current;
        });
      }, 100);
      
      // Still do a refetch in the background to ensure data consistency
      await refetchWorkspaces();
      
      // Clear the interval if it's still running
      clearInterval(processingInterval);
      
      // Set to 100% and complete
      setUploadProgress(100);
      setIsProcessing(false);
      
      // Close the modal after a short delay
      setTimeout(() => {
        setModal(null);
        setUploadProgress(0);
        setIsProcessing(false);
      }, 500);
      
      return presentationId;
    } catch (e) {
      setUploadProgress(0);
      setIsProcessing(false);
      if ((e as any).response?.data?.error === 'Document limit exceeded' || (e as any).response?.data?.error === 'Storage limit exceeded') {
        toggleStorageLimitExceededPrompt(true)
      } else if ((e as any).response?.status === 401) {
        toastr.error('Access denied', { timeout: 1000 })
      } else {
        toastr.error('Failed to upload document, please contact support!')
      }
      console.error('Upload error:', e)
      // Close the modal on error as well
      setModal(null)
      throw e;
    }
  }, [activeFolder, setModal, refetchWorkspaces, user, presentations])

  useEffect(() => {
    const updateWorkspaces = async () => {
      if (getWorkspacesResult) {
        const userWorkspaceDetails = getWorkspacesResult.workspace_members || []
        const workspaces = await Promise.all(userWorkspaceDetails.map(async (memberDetails: any) => {
          const workspacePlan =
            (getUserType(memberDetails.workspace.owner).isProTrial && trialPlan) ||
            memberDetails.workspace.owner.subscription?.plan ||
            freePlan
          const isPro =
            // Does the owner have a non-FREE plan
            (memberDetails?.workspace?.owner?.subscription?.plan &&
              !memberDetails?.workspace?.owner?.subscription?.plan.planId.includes('FREE')) ||
            // Or a protrial
            getUserType(memberDetails.workspace?.owner).isProTrial
          return {
            ...memberDetails.workspace,
            baseSeatCount: workspacePlan.seatCount,
            availableSeats: workspacePlan.seatCount + (memberDetails?.workspace?.subscription?.purchasedSeats || 0),
            isAdmin: user?.sub === memberDetails?.workspace?.owner.auth0_id,
            isProWorkspace: isPro,
            isOverfilled: memberDetails.workspace.workspace_members.length > (workspacePlan.seatCount || 999) + (memberDetails.workspace?.subscription?.purchasedSeats || 0),
            isFilled: memberDetails.workspace.workspace_members.length >= (workspacePlan.seatCount || 999) + (memberDetails.workspace?.subscription?.purchasedSeats || 0),
            isOverStorage: workspacePlan.storageLimit && memberDetails.workspace.presentations_aggregate.aggregate.sum.filesize > workspacePlan.storageLimit,
            fileCount: memberDetails.workspace?.presentations_aggregate?.aggregate?.count,
            isOverFileLimit: workspacePlan.documentLimit && memberDetails?.workspace?.presentations_aggregate?.aggregate?.count >= workspacePlan.documentLimit
          }
        }))
        setWorkspaces(workspaces)
        setCurrentUserWorkspace && setCurrentUserWorkspace(workspaces[workspaceIndex]?.workspace_id)
        setUserWorkspaces && setUserWorkspaces(workspaces)
        setPresentations(getWorkspacesResult.presentations)
      }
    }
    updateWorkspaces()
  }, [getWorkspacesResult, workspaceIndex, trialPlan, freePlan])

  return (
    <workspaceContext.Provider
      value={{
        workspaces,
        presentations,
        workspace: currentWorkspace,
        workspacesLoading,
        currentWorkspaceRoles: useMemo(() => (currentWorkspace?.roles || []), [currentWorkspace]),
        currentWorkspacePlan: (getUserType(currentWorkspace?.owner).isProTrial && trialPlan) || currentWorkspace?.owner?.subscription?.plan || freePlan,
        currentWorkspaceSeatPlan: currentWorkspace?.subscription?.plan || seatPlan,
        workspaceIndex,
        updateWorkspaceAvatar,
        updateWorkspaceName,
        removeInvite,
        removeUser,
        inviteUser,
        invites,
        acceptInvite,
        permissions,
        workspacePermissions: userPermissions,
        updateWorkspaceSeats,
        previewWorkspaceSeatCost,
        showStorageFilledPrompt: () => toggleStorageLimitExceededPrompt(true),
        hideStorageFilledPrompt: () => toggleStorageLimitExceededPrompt(false),
        showingStorageFilledPrompt: storageLimitExceededPromptVisible,
        joinWorkspace,
        createInvitationLink,
        deleteInvitationLink,
        updateLinkExtensions,
        hasWorkspacePermission: useCallback((p: string) => userPermissions.includes(p), [userPermissions]),
        folders: folders?.folders || [],
        addFolder,
        customDomains: currentWorkspace?.custom_domains || [],
        refetchWorkspaces,
        theme: currentWorkspace?.theme,
        setWorkspaceIdentifier,
        createWorkspaceRole: useCallback(async (roleName: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/workspaces/${currentWorkspace?.workspace_id}/roles`,
            data: { role: roleName }
          })
          await refetchWorkspaces()
        }, [currentWorkspace?.workspace_id, refetchWorkspaces]),
        setUserRole: useCallback(async (userId: string, roleId: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/workspaces/${currentWorkspace?.workspace_id}/members/${userId}/role`,
            data: { role: roleId }
          })
          await refetchWorkspaces()
        }, [currentWorkspace?.workspace_id, refetchWorkspaces]),
        setInviteRole: useCallback(async (inviteId: string, roleId: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/invites/${inviteId}/role`,
            data: { roleId }
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        removeRolePermission: useCallback(async (roleId: string, permission: string) => {
          await axios({
            method: 'DELETE',
            withCredentials: true,
            url: `/api/roles/${roleId}/permissions/${encodeURIComponent(permission)}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        addRolePermission: useCallback(async (roleId: string, permission: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/roles/${roleId}/permissions/${encodeURIComponent(permission)}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        renameRole: useCallback(async (roleId: string, name: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/roles/${roleId}/rename`,
            data: { name }
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        deleteRole: useCallback(async (roleId: string) => {
          await axios({
            method: 'DELETE',
            withCredentials: true,
            url: `/api/roles/${roleId}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        createWorkspace: useCallback(async (workspaceName: string, avatarFile: string) => {
          const formData = new FormData()
          formData.append('avatar', avatarFile)
          formData.append('name', workspaceName)
          await axios({
            method: 'POST',
            url: '/api/workspaces',
            data: formData,
            withCredentials: true
          })
          await tryExtendTrial('CreateWorkspace')
          await refetchWorkspaces()
        }, [tryExtendTrial, refetchWorkspaces]),
        setDefaultRole: useCallback(async (roleId: string) => {
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/roles/default`,
            data: { roleId },
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        addCustomDomain: useCallback(async (domain: string) => {
          try {
            await axios({
              method: 'POST',
              url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain`,
              data: { domain },
              withCredentials: true
            })
          } catch (e: any) {
            if (e.response.status === 400 && e.response.data.error === 'Domain in use') { throw new Error('Domain in use') } else { throw e }
          }
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        deleteCustomDomain: useCallback(async (domain: string) => {
          await axios({
            method: 'DELETE',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain/${domain}`,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        toggleCustomDomain: useCallback(async (domain: string, state: boolean) => {
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain/${domain}/toggle`,
            data: { state },
            withCredentials: true
          })
          await refetchWorkspaces()
          if (window.location.hostname === domain) {
            window.location.href = `${import.meta.env.VITE_ROOT_DOMAIN}${pathname}`
          }
        }, [pathname, refetchWorkspaces, currentWorkspace?.workspace_id]),
        updateTheme: useCallback(async (banner: File) => {
          const formData = new FormData()
          formData.append('banner', banner)
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/theme`,
            data: formData,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        renameDocument: useCallback(async (documentId: string, title: string) => {
          await axios({
            method: 'POST',
            url: `/api/documents/${documentId}/rename`,
            data: { title },
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        deleteDocument: useCallback(async (documentId: string) => {
          await axios({
            method: 'DELETE',
            url: `/api/documents/${documentId}`,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        uploadProgress,
        isProcessing,
        uploadDocument,
        importDocument: useCallback(async ({ url, title, authorization }: {url: string, title: string, authorization: string}) => {
          setModal('create_document')
          try {
            await axios({
              method: 'POST',
              url: `/api/folder/${activeFolder}/import`,
              withCredentials: true,
              data: { url, title, authorization },
              headers: { 'Content-Type': 'application/json' }
            })
            await refetchWorkspaces()
          } catch (e) {
            if ((e as any).response.data.error === 'Document limit exceeded' || (e as any).response.data.error === 'Storage limit exceeded') {
              toggleStorageLimitExceededPrompt(true)
            }
          }
          setModal(null)
        }, [activeFolder, setModal, refetchWorkspaces]),
        createDraftPresentation: useCallback(async () => {
          setModal('create_document')
          try {
            await axios({
              method: 'POST',
              url: `/api/folder/${activeFolder}/draft`,
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' }
            })
            await tryExtendTrial('CreateDocument')
            await refetchWorkspaces()
          } catch (e) {
            if ((e as any).response.data.error === 'Document limit exceeded' || (e as any).response.data.error === 'Storage limit exceeded') {
              toggleStorageLimitExceededPrompt(true)
            }
          }
          setModal(null)
        }, [activeFolder, refetchWorkspaces, setModal, tryExtendTrial])
      }}
    >
      <OrganisationProvider>
        {children}
      </OrganisationProvider>
    </workspaceContext.Provider>
  )
}
