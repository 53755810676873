import React, { useCallback, useState } from 'react';
import { ProPopup } from 'src/modules/modals/components/ProPopup';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const BurnTimerInput = ({
  label,
  isDisabled,
  value,
  onChange,
  defaultValue = null,
}: {
  label?: string;
  isDisabled?: boolean;
  value?: number;
  onChange?: (burnTimer: number | null) => Promise<void>;
  defaultValue: number | null;
}) => {
  const [time, setTime] = useState(defaultValue || 1);
  const [isChecked, setChecked] = useState(!!defaultValue);
  const { t } = useTranslation();

  const options = [1, 5, 10, 30, 60, 120, 240, 360, 720, 1440, 10080];

  const handleChangeTimer = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setChecked(true);
    setTime(parseInt(value));
    onChange && onChange(parseInt(value));
  }, [onChange, setChecked, setTime]);

  const handleToggle = useCallback((_e: React.MouseEvent<HTMLButtonElement>) => {
    setChecked(c => !c);
    onChange && onChange(!isChecked ? time : null);
  }, [onChange, setChecked, isChecked, time]);

  return (
    <ProPopup requiredFeature='shareTimers'>
      <StyledBurnTimerContainer className='burn-input-timer'>
        <ToggleButton 
          disabled={isDisabled} 
          type='button' 
          onClick={handleToggle} 
          role="switch"
          aria-checked={isChecked}
          data-state={isChecked ? "checked" : "unchecked"}
        >
          <ToggleThumb data-state={isChecked ? "checked" : "unchecked"} />
        </ToggleButton>
        <InputWrapper>
          <LabelText htmlFor='burnTimeout' title={t('setupPopup.Burn timer tooltip')}>
            {label || t('Burn after')}
          </LabelText>
          <StyledSelect
            name='burnTimeout'
            value={time}
            disabled={!!isDisabled}
            onChange={handleChangeTimer}
          >
            {options.map(o => (
              <option key={o} value={o}>
                {t(`time.${o}`)}
              </option>
            ))}
          </StyledSelect>
        </InputWrapper>
      </StyledBurnTimerContainer>
    </ProPopup>
  );
};

const StyledBurnTimerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 4px;
  width: 100%;
`;

const LabelText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  line-height: 1.3;
  margin-right: 12px;
  width: auto;
`;

const StyledSelect = styled.select`
  width: 120px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  background-color: color-mix(in srgb, var(--background-color) 95%, var(--text-color) 5%);
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 8px;
  height: 36px;
  
  &:hover:not(:disabled) {
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border-color: var(--border-color-dark);
  }
  
  &:focus {
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ToggleButton = styled.button`
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
  border: 1px solid var(--border-color);
  cursor: pointer;
  padding: 0;
  flex-shrink: 0;
  transition: all 0.2s ease;
  
  &[data-state="checked"] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ToggleThumb = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &[data-state="checked"] {
    transform: translateX(20px);
  }
`;

export default BurnTimerInput;
