import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { FiX } from 'react-icons/fi';
import { workspaceContext } from 'src/context';

const CreateDocumentModal = ({ dismissModal }) => {
  const { uploadProgress, isProcessing } = useContext(workspaceContext);
  const [displayProgress, setDisplayProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  // Smooth progress updates
  useEffect(() => {
    let animationFrame;
    const smoothProgress = () => {
      setDisplayProgress(prev => {
        const diff = uploadProgress - prev;
        const increment = Math.max(0.5, diff * 0.1); // Adjust speed based on difference
        const next = prev + (diff > 0 ? increment : 0);
        
        if (Math.abs(next - uploadProgress) < 0.5) {
          return uploadProgress;
        }
        
        animationFrame = requestAnimationFrame(smoothProgress);
        return next;
      });
    };
    
    smoothProgress();
    return () => cancelAnimationFrame(animationFrame);
  }, [uploadProgress]);

  // Handle completion
  useEffect(() => {
    if (uploadProgress >= 100 && !isProcessing) {
      const timer = setTimeout(() => {
        setIsComplete(true);
        dismissModal();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [uploadProgress, isProcessing, dismissModal]);

  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={dismissModal} aria-label="Close">
          <FiX />
        </CloseButton>
        
        <ModalContent>
          <UploadIcon>
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g className="upload-animation">
                <path 
                  className="arrow"
                  d="M16 3.5V16M16 3.5L11 8.5M16 3.5L21 8.5" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  className="line"
                  d="M10 20.5H22" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round"
                />
              </g>
            </svg>
          </UploadIcon>
          
          <Title>
            {isComplete ? 'Upload complete' : 'Preparing document'}
          </Title>
          
          <ProgressBarContainer>
            <ProgressBar>
              <ProgressIndicator 
                progress={displayProgress} 
                isProcessing={isProcessing}
              />
            </ProgressBar>
            <ProgressText>{Math.round(displayProgress)}%</ProgressText>
          </ProgressBarContainer>
          
          <StatusMessage>
            {!isComplete && (
              isProcessing ? 'Processing document...' : 'Uploading document...'
            )}
          </StatusMessage>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-overlay-color, rgba(0, 0, 0, 0.4));
  backdrop-filter: blur(3px);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: var(--modal-background-color, var(--background-color));
  border-radius: 12px;
  box-shadow: var(--modal-shadow);
  position: relative;
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: var(--close-button-background, rgba(0, 0, 0, 0.1));
  color: var(--text-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;

  &:hover {
    background: var(--close-button-hover-background, rgba(0, 0, 0, 0.15));
    color: var(--text-color);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ModalContent = styled.div`
  padding: 32px;
  text-align: center;
`;

const UploadIcon = styled.div`
  width: 80px;
  height: 80px;
  background-color: var(--primary-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: white;
  position: relative;
  overflow: hidden;

  svg {
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      transparent,
      rgba(255, 255, 255, 0.15),
      transparent
    );
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-100%) rotate(45deg);
    }
    100% {
      transform: translateX(100%) rotate(45deg);
    }
  }

  @media (max-width: 480px) {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    margin-bottom: 20px;

    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin: 0 0 24px;
  letter-spacing: -0.02em;
`;

const ProgressBarContainer = styled.div`
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: var(--progress-background, rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 8px;
`;

const ProgressIndicator = styled.div`
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 6px;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.15),
      transparent
    );
    animation: ${props => props.isProcessing ? 'shimmerProcessing 1.2s infinite' : 'shimmer 2s infinite'};
  }
`;

const ProgressText = styled.div`
  font-size: 14px;
  color: var(--text-secondary-color);
  font-variant-numeric: tabular-nums;
`;

const StatusMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-secondary-color);
  font-size: 14px;
  min-height: 20px;
`;

export default CreateDocumentModal;