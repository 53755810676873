import React, { useCallback, useContext, useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FiFileText } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { link, mail } from '@sizle-icons';
import gotoCharts from '@sizle-icons/goto-charts.svg';
import options from '@sizle-icons/options.svg';
import { presentationPlaceholder } from 'src/design-system/icons'; // Ensure this is correctly imported
import { useModal } from 'src/modules/modals';
import { sendAmplitudeData } from 'src/utils/amplitude';
import PresentationOptionsPopupMenu from './PresentationOptionsPopupMenu';
import { workspaceContext, useUserContext } from 'src/context';
import { getFullName } from 'src/utils/user';
import { Box, Tooltip } from '@chakra-ui/core';

const PresentationItem = ({
  presentationId,
  presentation,
  originalFileUrl,
  originalFileType,
  owner,
  title,
  url,
  lastEdited,
  emailNotificationsEnabled,
  openTrackingEnabled,
  formEnabled,
  selectedLead, // lead recipient is locked if this is set
  onPresentationToggle
}) => {
  const navigate = useNavigate();
  const { avatarCacheTimestamp, workspaceIndex } = useUserContext();
  const { setModal } = useModal();
  const dismissModal = () => setModal(null);
  const { hasWorkspacePermission } = useContext(workspaceContext);
  const [optionsPopup, setOptionsPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const optionsButtonRef = useRef(null);
  const itemRef = useRef(null);

  // Handle clicking outside to close popup
  useEffect(() => {
    if (!optionsPopup) return;
    
    const handleClickOutside = (event) => {
      // Only handle clicks that are not on the options button itself
      if (
        optionsButtonRef.current && 
        !optionsButtonRef.current.contains(event.target) &&
        // Ignore clicks that happened inside the popup menu
        event.target.closest('[data-testid="options-popup-menu"]') === null
      ) {
        setOptionsPopup(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [optionsPopup]);

  const onClickPresentation = (e) => {
    e.preventDefault();
    closeOptionsPopup();
    if (hasWorkspacePermission('Analytics & Reports')) {
      navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics`);
    }
  };

  const onClickOpenShareModal = () => {
    sendAmplitudeData('OPENING_SHARE_MODAL');
    setModal('share_presentation', {
      title,
      presentationId,
      openTrackingEnabled,
      emailNotificationsEnabled,
      closePopup: dismissModal
    });
  };

  const onClickAnalytics = (e) => {
    navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics`);
  };

  const startMoving = useCallback((ev) => {
    ev.dataTransfer.setData('presentationId', presentationId);
  }, [presentationId]);

  const dragOver = useCallback((ev) => {
    ev.dataTransfer.dropEffect = 'move';
    ev.preventDefault();
  }, []);

  const closeOptionsPopup = useCallback(() => {
    setOptionsPopup(false);
  }, []);

  const toggleOptionsPopup = useCallback((e) => {
    // Stop the event to prevent it from reaching the parent card
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    
    // Toggle the popup state
    setOptionsPopup(prev => !prev);
  }, []);

  return (
    <Element
      ref={itemRef}
      draggable={!optionsPopup}
      onDragStart={startMoving}
      onDragOver={dragOver}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
      optionsPopup={optionsPopup}
    >
      {owner && (
        <AvatarOwner>
          <Tooltip label={getFullName(owner)} placement='left' zIndex='4'>
            <Box as='span'>
              <img className='avatar-small' size='sm' name={getFullName(owner)} src={`/api/users/${owner.auth0_id}/avatar?timestamp=${avatarCacheTimestamp.getMilliseconds()}`} />
            </Box>
          </Tooltip>
        </AvatarOwner>
      )}
      <PresentationThumbnail 
        className='presentation-thumbnail' 
        bg={`/api/documents/thumbnail/${presentationId}/0?timestamp=${presentation.updated_at}`} 
        onClick={(e) => onClickPresentation(e)}
      >
        {!presentation.updated_at && <FiFileText />}
        <GradientOverlay />
      </PresentationThumbnail>
      <PresentationDetails className='presentation-details'>
        <Title onClick={(e) => onClickPresentation(e)}>{title}</Title>
        <PresoControl className='preso-control'>
          <ShareButton onClick={onClickOpenShareModal}>Share</ShareButton>
          <AnalyticsButton onClick={onClickAnalytics}>
            <span className="icon" />
            Leads
          </AnalyticsButton>
          <OptionsButton
            ref={optionsButtonRef}
            onClick={toggleOptionsPopup}
            isActive={optionsPopup}
            aria-label="More options"
            data-testid="options-button"
          >
            <span className="icon" />
          </OptionsButton>
        </PresoControl>
      </PresentationDetails>
      {optionsPopup && (
        <PopupContainer onClick={(e) => e.stopPropagation()}>
          <PresentationOptionsPopupMenu
            title={title}
            presentationId={presentationId}
            closePopup={closeOptionsPopup}
            onClickAnalytics={onClickAnalytics}
            openTrackingEnabled={openTrackingEnabled}
            emailNotificationsEnabled={emailNotificationsEnabled}
            triggerRef={optionsButtonRef}
            parentRef={itemRef}
          />
        </PopupContainer>
      )}
    </Element>
  );
}

const PresoControl = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  transition: all 0.3s ease-in-out;
  gap: 8px;
  
  @media (max-width: 480px) {
    padding: 8px 12px;
  }
`;

const ShareButton = styled.button`
  border: 1px solid var(--border-color-light);
  color: var(--white-color);
  font-weight: 500;
  background: linear-gradient(135deg, #5d55fa, #3e38ae);
  border-radius: 6px;
  padding: 6px 16px;
  font-size: 0.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(6px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const AnalyticsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 6px 16px;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: var(--background-hover-color);
    border-color: var(--border-color-dark);
  }

  &:active {
    background: var(--background-active-color);
  }

  .icon {
    width: 16px;
    height: 16px;
    background-color: var(--text-color);
    -webkit-mask: url(${gotoCharts}) no-repeat center;
    mask: url(${gotoCharts}) no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
`;

const OptionsButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
  margin-left: auto;
  z-index: 12;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--background-hover-color);
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    background-color: var(--text-color);
    -webkit-mask: url(${options}) no-repeat center;
    mask: url(${options}) no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    opacity: ${props => props.isActive ? 1 : 0.6};
    transition: opacity 0.2s ease;
    position: relative;
    z-index: 1;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
    
    .icon {
      opacity: 1;
    }
  }
  
  &:active {
    &::after {
      background-color: var(--background-active-color);
    }
  }
`;

const Element = styled.div(({ isHovered, optionsPopup }) => css`
  width: 100%;
  height: auto;
  min-width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  position: relative;
  border-radius: 12px;
  transition: all 0.2s ease;
  background-color: var(--background-highlight-color);
  border: 1px solid var(--border-alt);
  padding-bottom: 15px;
  overflow: visible;
  z-index: ${optionsPopup ? '11' : '1'};
  
  ${isHovered && css`
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  `}
`);

const PresentationThumbnail = styled.div(props => css`
  width: 100%;
  height: 120px;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  /* Two backgrounds: The image on top, gradient behind it */
  background: ${props.bg ? `url('${props.bg}'), linear-gradient(270deg, navy, purple, navy)` : `linear-gradient(270deg, navy, purple, navy)`};
  background-repeat: no-repeat, no-repeat;
  background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
  background-size: ${props.bg ? 'cover, 600% 600%' : '600% 600%'};
  animation: gradientAnimation 20s ease infinite;

  ${!props.bg && css`
    &::after {
      content: '';
      display: block;
      width: 64px;
      height: 64px;
      background-color: var(--text-color);
      mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'/%3E%3Cpolyline points='14 2 14 8 20 8'/%3E%3Cline x1='16' y1='13' x2='8' y2='13'/%3E%3Cline x1='16' y1='17' x2='8' y2='17'/%3E%3Cline x1='10' y1='9' x2='8' y2='9'/%3E%3C/svg%3E") no-repeat center / contain;
      -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'/%3E%3Cpolyline points='14 2 14 8 20 8'/%3E%3Cline x1='16' y1='13' x2='8' y2='13'/%3E%3Cline x1='16' y1='17' x2='8' y2='17'/%3E%3Cline x1='10' y1='9' x2='8' y2='9'/%3E%3C/svg%3E") no-repeat center / contain;
    }

    &::before {
      content: 'No Preview Available';
      font-size: 12px;
      color: var(--text-color);
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  `}

  @keyframes gradientAnimation {
    0% {
      background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
    }
    50% {
      background-position: ${props.bg ? 'center center, 100% 50%' : '100% 50%'};
    }
    100% {
      background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
    }
  }
`);

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 95%; /* Adjust the height as needed */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
`;

const PresentationDetails = styled.div`
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px 0 10px;
  position: relative;
  width: 100%;
`;

const AvatarOwner = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 2;
  
  .avatar-small {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid var(--background-color);
  }
`;

const Title = styled.div`
  font-size: 0.9rem;
  line-height: 1em;
  font-weight: 500;
  color: var(--text-color);
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 12px;
  margin-bottom: 5px;
  margin-left: 6px;

  @media only screen and (max-width: 580px) {
    font-size: 14px;
  }
`;

const PresentationOptions = styled.div`
  cursor: pointer;
  margin-right: 5%;
`;

const SelectedLeadContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`;

const SelectedLeadLabel = styled.div`
  font-weight: 600;
  color: var(--text-color);
`;

const LeadType = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: var(--text-color-muted);
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
  pointer-events: none;
  
  /* All children should receive pointer events */
  > * {
    pointer-events: auto;
  }
`;

export default PresentationItem;
