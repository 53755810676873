import React, { useContext } from 'react'
import { Dropzone } from 'src/design-system/elements'
import { workspaceContext } from 'src/context'
import { toastr } from 'react-redux-toastr'

const DashboardDropzone = ({ children }) => {
  const { uploadDocument, workspace, showStorageFilledPrompt } = useContext(workspaceContext)

  const submit = async (files) => {
    console.warn('Uploading')
    if (workspace.isOverStorage) {
      showStorageFilledPrompt()
      throw new Error('Storage overfilled')
    } else {
      try {
        await uploadDocument(files[0])
        // Show success message when upload completes
        toastr.success('Document uploaded successfully')
      } catch (error) {
        console.error('Upload error:', error)
        toastr.error('Upload failed. Please try again.')
      }
    }
  }

  return (
    <Dropzone submit={submit}>
      {children}
    </Dropzone>
  )
}

export default DashboardDropzone
