import React, { useEffect, useState, useContext } from 'react';
import SVGInline from 'react-svg-inline';
import axios from 'axios';
import { useAnalytics } from 'use-analytics';
import { lockBlack, lightMode } from '@sizle-icons';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useUserContext } from 'src/context';
import { ModeContext } from 'src/context/ModeContext';
import { useTheme } from 'src/context/ThemeContext';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { MainProfileForm } from 'src/modules/account';
import SizleLoader from 'src/modules/controls/components/SizleLoader';
import { useNavigate } from 'react-router-dom';

const AccountPage = () => {
  const { user } = useUserContext();
  const { mode, setMode } = useContext(ModeContext);
  const { isDarkMode, toggleTheme } = useTheme();
  const { page } = useAnalytics();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoadingProfile, toggleLoadingProfile] = useState(false);
  const [isChangingPassword, setChangingPassword] = useState(false);
  const [nameField, setNameField] = useState('');
  const [emailField, setEmailField] = useState('');

  const appMetadata = user?.['https://sizle.io/app_metadata'] || {};
  const isSocialAccount = userDetails?.identities?.[0]?.isSocial;

  // Events
  const onThemeChange = () => {
    // Toggle ThemeContext
    toggleTheme();
    
    // Update ModeContext
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    
    // Log the change for analytics
    sendAmplitudeData('THEME_CHANGED', { theme: newMode });
  };

  const onChangePassword = async () => {
    setChangingPassword(true);
    const result = await axios.post('/api/self/resetPassword', {}, { withCredentials: true });

    if (result) {
      toastr.info(t('Password update initiated, please check your email inbox.'));
      sendAmplitudeData('CHANGE_USER_PASSWORD_REQUESTED');
    }
    setChangingPassword(false);
  };

  const navigateToBilling = () => {
    navigate('/billing');
  };

  // Effects
  useEffect(() => {
    page();
    sendAmplitudeData('PAGE_VIEW_ACCOUNT');
  }, []); //eslint-disable-line

  useEffect(() => {
    const fetchUserDetails = async () => {
      const result = await axios.get('/api/self');

      if (result) {
        setUserDetails(result.data);
        setNameField(result.data.name || '');
        setEmailField(result.data.email || '');
        toggleLoadingProfile(false);
      }
    };

    if (!userDetails) {
      toggleLoadingProfile(true);
      fetchUserDetails();
    }
  }, []); //eslint-disable-line

  return (
    <div className='page-container account-page'>
      <div className="page-header">
        <h1>Settings</h1>
      </div>

      {isLoadingProfile && <SizleLoader />}
      {!isLoadingProfile && userDetails && (
        <>
          <div className='page-section'>
            <div className='ws-details'>
              <div className='avatar-image'>
                <div className='avatar'>
                  {userDetails.picture && (
                    <img src={userDetails.picture} alt="Profile" />
                  )}
                </div>
                <input 
                  type="file"
                  id="profile-avatar"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    // Handle file upload logic here
                    console.log('File selected:', e.target.files[0]);
                  }}
                />
                <label htmlFor="profile-avatar" className="btn secondary">
                  Change
                </label>
              </div>
              
              <div className='ws-name'>
                <label className='label'>Name</label>
                <input 
                  type="text"
                  className='input-text'
                  value={nameField}
                  disabled
                />
                <label className='label'>Email</label>
                <input 
                  type="email"
                  className='input-text'
                  value={emailField}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className='page-section'>
            <div className='section-header'>
              <h4>Account</h4>
            </div>
            <div className='section-content'>
              <button className="btn secondary" onClick={navigateToBilling}>
                Manage subscription
              </button>
            </div>
          </div>

          <div className='page-section'>
            <div className='section-header'>
              <h4>Preferences</h4>
            </div>
            <div className='section-content'>
              <div className='theme-toggle'>
                <button className={`switch ${mode === 'dark' ? 'on' : 'off'}`} onClick={onThemeChange} />
                <label>Toggle light and dark mode</label>
              </div>

              {!userDetails?.identities?.[0]?.isSocial && (
                <button 
                  className="btn secondary" 
                  onClick={onChangePassword} 
                  disabled={isChangingPassword}
                >
                  Change password
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountPage;
