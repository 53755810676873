import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { ProPopup } from 'src/modules/modals/components/ProPopup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { HiOutlineSelector, HiSearch } from 'react-icons/hi';
import { FiPlusSquare, FiRefreshCw } from 'react-icons/fi';
import { subscriptionContext, workspaceContext } from 'src/context';
import { useModal } from 'src/modules/modals';
import { useResponsive } from 'react-hooks-responsive';
import { useKeyboardShortcut } from 'src/hooks/useKeyboardShortcut';
import { reloadWorkspaceData } from 'src/api/interceptors';
import { toastr } from 'react-redux-toastr';

interface WorkspaceDropdownProps {
  toggleNav: (action: 'open' | 'close') => void;
}

interface Workspace {
  workspace_id: string;
  name: string;
  updated_at: string;
  isAdmin: boolean;
  isOverfilled: boolean;
  workspace_members: Array<{
    member_type: { value: string };
    user: { email: string };
  }>;
}

// Safe access helper function for workspace properties
const safeAccess = (obj: any, path: string, defaultValue: any = undefined) => {
  if (!obj) return defaultValue;
  const keys = path.split('.');
  return keys.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : defaultValue;
  }, obj);
};

const getInitials = (name: string = '') => {
  if (!name) return '';
  return name
    .split(' ')
    .map(word => word?.[0] || '')
    .filter(Boolean)
    .join('')
    .slice(0, 2);
};

const WorkspaceAvatar = ({ workspace, size = 'normal' }: { workspace?: Workspace; size?: 'normal' | 'small' }) => {
  const [imageError, setImageError] = useState(false);
  
  if (!workspace || !safeAccess(workspace, 'workspace_id')) {
    return <div className="avatar-small no-image" data-initials=""></div>;
  }
  
  const initials = getInitials(safeAccess(workspace, 'name', ''));
  const workspaceId = safeAccess(workspace, 'workspace_id', '');
  const timestamp = safeAccess(workspace, 'updated_at', '');
  const avatarUrl = `/api/workspaces/${workspaceId}/avatar?timestamp=${timestamp}`;

  return (
    <div className={`avatar-small ${imageError ? 'no-image' : ''}`} data-initials={initials}>
      {!imageError && (
        <img
          src={avatarUrl}
          onError={() => setImageError(true)}
          alt=""
          aria-hidden="true"
        />
      )}
    </div>
  );
};

const WorkspaceDropdown: React.FC<WorkspaceDropdownProps> = ({ toggleNav }) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { workspaces, workspaceIndex, workspace, refetchWorkspaces, workspacesLoading } = useContext(workspaceContext);
  const { currentPlan } = useContext(subscriptionContext);
  const [workspaceDropdownOpen, setWorkspaceDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isRetrying, setIsRetrying] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { track } = useAnalytics();
  const { setModal } = useModal();
  const breakpoints = { mobile: 0, desktop: 855 };
  const { screenIsAtMost, screenIsAtLeast } = useResponsive(breakpoints);
  const isMobile = screenIsAtLeast('mobile') && screenIsAtMost('desktop');

  const handleRetryLoad = () => {
    setIsRetrying(true);
    
    // First try to refetch if available
    if (refetchWorkspaces) {
      refetchWorkspaces()
        .then(() => {
          toastr.success('Data refreshed', 'Workspace information has been reloaded');
        })
        .catch(() => {
          toastr.error('Refresh failed', 'Please try again later');
        })
        .finally(() => {
          setIsRetrying(false);
        });
    } else {
      // Otherwise use the helper from interceptors
      reloadWorkspaceData();
      setTimeout(() => setIsRetrying(false), 3000);
    }
    
    // Show loading message
    toastr.info('Reloading data', 'Please wait while we reload your workspace data', { timeOut: 3000 });
  };

  const limitCreateMoreWorkspace = ((workspaces as Workspace[]) || [])
    .filter(w => w && w.isAdmin)
    .length >= (currentPlan?.workspaceCount || 0);

  const filteredWorkspaces = ((workspaces as Workspace[]) || [])
    .filter(w => 
      w && safeAccess(w, 'name', '').toLowerCase().includes((searchQuery || '').toLowerCase())
    );

  const toggleDropdown = useCallback(() => {
    setWorkspaceDropdownOpen((prevState) => {
      if (!prevState) {
        // Reset search and active index when opening
        setSearchQuery('');
        setActiveIndex(-1);
      }
      return !prevState;
    });
  }, []);

  const closeDropdown = useCallback(() => {
    setWorkspaceDropdownOpen(false);
    setSearchQuery('');
    setActiveIndex(-1);
  }, []);

  // Keyboard shortcut to open/close dropdown
  useKeyboardShortcut('cmd+k', (e: KeyboardEvent) => {
    e.preventDefault();
    if (!workspaceDropdownOpen) {
      toggleDropdown();
      // Focus search input when opening with keyboard
      setTimeout(() => searchInputRef.current?.focus(), 0);
    } else {
      closeDropdown();
    }
  });

  const navigateWorkspace = useCallback(
    (i: number) => (e?: React.MouseEvent) => {
      e?.stopPropagation();
      setWorkspaceDropdownOpen(false);
      if ((workspaces as Workspace[])[i].isOverfilled && !(workspaces as Workspace[])[i].isAdmin) {
        setModal('overfilled', {
          adminEmail: (workspaces as Workspace[])[i].workspace_members.find((u) => u.member_type.value === 'admin')?.user.email,
        });
      } else {
        navigate(`/s/${i}/presentations`);
        if (isMobile) toggleNav('close');
        track('switch-workspace', {
          from: workspaceIndex,
          to: i,
        });
      }
    },
    [isMobile, navigate, setModal, toggleNav, track, workspaceIndex, workspaces]
  );

  useEffect(() => {
    if (hash === '#create-workspace') {
      setTimeout(() => setWorkspaceDropdownOpen(true), 1);
    }
  }, [hash]);

  const onClickCreateNewWorkspace = (e: React.MouseEvent) => {
    e.stopPropagation();
    setWorkspaceDropdownOpen(false);
    setModal('create_workspace');
    track('click-create-workspace');
  };

  // Handle keyboard navigation
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (!workspaceDropdownOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prev => 
          prev < filteredWorkspaces.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0 && activeIndex < filteredWorkspaces.length) {
          navigateWorkspace(workspaces.indexOf(filteredWorkspaces[activeIndex]))();
        }
        break;
      case 'Escape':
        e.preventDefault();
        closeDropdown();
        break;
    }
  }, [workspaceDropdownOpen, filteredWorkspaces, activeIndex, navigateWorkspace, closeDropdown, workspaces]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    if (workspaceDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [workspaceDropdownOpen, closeDropdown, handleKeyDown]);

  // Loading state
  if (workspacesLoading) {
    return (
      <div className="workspace-dropdown workspace-loading" aria-busy="true">
        <div className="avatar-small loading"></div>
        <div className="workspace-info">
          <span className="workspace-name loading-placeholder"></span>
          <span className="keyboard-shortcut" aria-hidden="true">⌘K</span>
        </div>
        <HiOutlineSelector className="dropdown-icon" aria-hidden="true" />
      </div>
    );
  }

  // Error state - no workspaces available
  if (!workspaces || workspaces.length === 0 || !workspace) {
    return (
      <div className="workspace-dropdown workspace-error">
        <div className="avatar-small no-image" data-initials=""></div>
        <div className="workspace-info">
          <span className="workspace-name error">No workspace available</span>
          <button 
            className="retry-button" 
            onClick={handleRetryLoad}
            disabled={isRetrying}
          >
            <FiRefreshCw className={isRetrying ? 'rotating' : ''} />
            {isRetrying ? 'Retrying...' : 'Retry'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={dropdownRef}
      className="workspace-dropdown"
      role="button"
      tabIndex={0}
      onClick={toggleDropdown}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          toggleDropdown();
        }
      }}
      aria-expanded={workspaceDropdownOpen}
      aria-haspopup="true"
      aria-label="Select workspace"
    >
      <WorkspaceAvatar workspace={workspace as Workspace} />
      <div className="workspace-info">
        <span className="workspace-name" aria-label="Current workspace">
          {(workspace as Workspace)?.name || ''}
        </span>
        <span className="keyboard-shortcut" aria-hidden="true">⌘K</span>
      </div>
      <HiOutlineSelector className={`dropdown-icon ${workspaceDropdownOpen ? 'open' : ''}`} aria-hidden="true" />
      
      {workspaceDropdownOpen && (
        <div 
          className="select-list"
          role="listbox"
          aria-label="Workspace list"
        >
          <div className="search-container">
            <HiSearch className="search-icon" aria-hidden="true" />
            <input
              ref={searchInputRef}
              type="text"
              className="workspace-search"
              placeholder="Search workspaces..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search workspaces"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="workspace-list" role="group">
            {filteredWorkspaces.map((w, i) => (
              <div
                className={`menu-item ${activeIndex === i ? 'active' : ''}`}
                key={w.workspace_id}
                onClick={navigateWorkspace(workspaces.indexOf(w))}
                onMouseEnter={() => setActiveIndex(i)}
                role="option"
                aria-selected={workspaces.indexOf(w) === workspaceIndex}
                tabIndex={-1}
              >
                <WorkspaceAvatar workspace={w} size="small" />
                <span className="name">{w?.name || ''}</span>
                {workspaces.indexOf(w) === workspaceIndex && (
                  <span className="current-indicator" aria-hidden="true">Current</span>
                )}
              </div>
            ))}
            
            {filteredWorkspaces.length === 0 && (
              <div className="no-results">
                No workspaces found
              </div>
            )}
          </div>

          <ProPopup 
            title="Workspace limit reached" 
            subtitle="Upgrade to create more workspaces"  
            active={currentPlan?.plan_id === 'SOLO_TRIAL' || limitCreateMoreWorkspace}
          >
            <div 
              className="menu-item create-workspace" 
              onClick={onClickCreateNewWorkspace}
              role="button"
              tabIndex={0}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  onClickCreateNewWorkspace(e as any);
                }
              }}
            >
              <FiPlusSquare aria-hidden="true" />
              <span className="name">Create new workspace</span>
            </div>
          </ProPopup>
        </div>
      )}
    </div>
  );
};

export default WorkspaceDropdown;
