import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // First check sizle_mode which is used by ModeContext
    const sizleMode = localStorage.getItem('sizle_mode');
    if (sizleMode) {
      return sizleMode === 'dark';
    }
    
    // Fall back to theme if sizle_mode is not set
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Listen for mode-changed events from ModeContext
  useEffect(() => {
    const handleModeChange = (e) => {
      const { mode } = e.detail;
      setIsDarkMode(mode === 'dark');
    };
    
    window.addEventListener('mode-changed', handleModeChange);
    
    return () => {
      window.removeEventListener('mode-changed', handleModeChange);
    };
  }, []);

  useEffect(() => {
    const theme = isDarkMode ? 'dark' : 'light';
    
    // Set both theme attributes for compatibility
    document.documentElement.setAttribute('data-theme', theme);
    document.documentElement.classList.remove(isDarkMode ? 'light' : 'dark');
    document.documentElement.classList.add(theme);
    
    // Store theme in both localStorage keys for compatibility
    localStorage.setItem('theme', theme);
    localStorage.setItem('sizle_mode', theme);

    // Also update root element for consistency
    const rootElm = document.getElementById('root');
    if (rootElm) {
      rootElm.classList.remove(isDarkMode ? 'light' : 'dark');
      rootElm.classList.add(theme);
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeContext; 