import React, { useState } from 'react'
import 'pure-react-carousel/dist/react-carousel.es.css'
import OnboardingSlider from './OnboardingSlider'
import styled from '@emotion/styled'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const OnboardingModal = ({ dismissModal }) => {
  const onClose = async () => {
    dismissModal()
    localStorage.setItem('sizle.lastSeenOnboardingModal', new Date())
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <ErrorBoundary>
          <OnboardingSlider dismissModal={onClose} />
        </ErrorBoundary>
      </ModalContainer>
    </ModalOverlay>
  )
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
  padding: 16px;
`

const ModalContainer = styled.div`
  width: 100%;
  max-width: 720px;
  animation: fadeIn 0.25s ease-out;
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0 auto;
  }
  
  @media (max-height: 700px) {
    margin: 0 auto;
  }
`

export default OnboardingModal
