import React, { useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { DateTimePicker } from 'src/design-system/elements'
import styled from '@emotion/styled'

const ExpirationDateInput = ({
  label,
  isDisabled,
  defaultValue = null,
  onChange
}: {
  label?: string,
  isDisabled?: boolean,
  value?: Date,
  defaultValue: Date | null,
  onChange: (expirationDate: Date | null) => Promise<void> | null
}) => {
  const [expirationDate, setExpirationDate] = useState(defaultValue)
  const [toggled, toggle] = useState(!!defaultValue)
  const inputRef = useRef()
  const { t } = useTranslation()

  const handleDateUpdated = useCallback((value: Date) => {
    setExpirationDate(value)
    toggle(true)
    // We can't use state stored value yet, as its stored async
    onChange && onChange(value)
  }, [toggled, onChange, expirationDate])

  const handleToggle = useCallback((_event: React.MouseEvent<HTMLButtonElement>) => {
    toggle((t) => !t)
    // We can't use state stored value yet, as its stored async
    onChange && onChange(!toggled ? expirationDate : null)
  }, [expirationDate, toggled, onChange])

  return (
    <ProPopup requiredFeature='shareTimers'>
      <StyledExpiryDatePicker className='expired-datepicker'>
        <ToggleButton 
          disabled={isDisabled} 
          type='button' 
          onClick={handleToggle} 
          role="switch"
          aria-checked={toggled}
          data-state={toggled ? "checked" : "unchecked"}
        >
          <ToggleThumb data-state={toggled ? "checked" : "unchecked"} />
        </ToggleButton>
        <InputWrapper>
          <LabelText htmlFor='expirationDate' title={t('setupPopup.Expiration date tooltip')}>
            {label || t('Expiry')}
          </LabelText>
          <DateTimePicker
            required={toggled}
            placeholder='Choose date'
            padding='10px 8px'
            maxWidth='180px'
            minWidth='180px'
            ref={inputRef}
            name='expirationDate'
            open
            selected={expirationDate}
            isDisabled={isDisabled}
            onChange={handleDateUpdated}
            minDate={new Date()}
          />
        </InputWrapper>
      </StyledExpiryDatePicker>
    </ProPopup>
  )
}

const StyledExpiryDatePicker = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 4px;
  width: 100%;
`;

const LabelText = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  line-height: 1.3;
  margin-right: 12px;
  width: auto;
`;

const ToggleButton = styled.button`
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
  border: 1px solid var(--border-color);
  cursor: pointer;
  padding: 0;
  flex-shrink: 0;
  transition: all 0.2s ease;
  
  &[data-state="checked"] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ToggleThumb = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &[data-state="checked"] {
    transform: translateX(20px);
  }
`;

export default ExpirationDateInput
