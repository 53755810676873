import React, { useState, useContext, useEffect } from 'react'
import PinInput from './PinInput'
import ExpirationDateInput from './ExpirationDateInput'
import BurnTimerInput from './BurnTimerInput'
import styled from '@emotion/styled'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toastr } from 'react-redux-toastr'
import { Link, useNavigate } from 'react-router-dom'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { FiClipboard, FiSettings, FiChevronDown, FiMail } from 'react-icons/fi'
import { useAnalytics } from 'use-analytics'
import { useTranslation } from 'react-i18next'
import { workspaceContext } from 'src/context'
import { usePublicLink } from 'src/modules/presentations'
import gotoCharts from '@sizle-icons/goto-charts.svg';
import LabeledToggle from 'src/modules/presentations/components/popup/LabeledToggle'

const CreatePublicLinkTab = ({ presentationId, closeModal, requiredFeature, onSuccessStateChange }) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const [{ url, linkId }, createPublicLink] = usePublicLink();
  const { workspaceIndex } = useContext(workspaceContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [burnTimer, setBurnTimer] = useState(null);
  const [commentsEnabled, setCommentsEnabled] = useState(true);
  const [downloadsEnabled, setDownloadsEnabled] = useState(true);
  const [reactionsEnabled, setReactionsEnabled] = useState(true);
  const [requiresEmailCapture, setRequiresEmailCapture] = useState(false);
  const [displayingLinkCreated, toggleDisplayCreatedLink] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const { currentWorkspacePlan } = useContext(workspaceContext);
  const userFeatures = currentWorkspacePlan || {};

  // Notify parent component of success state change
  useEffect(() => {
    if (onSuccessStateChange) {
      onSuccessStateChange(displayingLinkCreated);
    }
  }, [displayingLinkCreated, onSuccessStateChange]);

  // Validate requiredFeature
  if (!requiredFeature) {
    console.warn('ProPopup: requiredFeature is missing or undefined.');
  }

  const preventAccess =
    requiredFeature && !(currentWorkspacePlan?.[requiredFeature] ?? false);

  const clickCreateButton = async () => {
    setLoading(true);

    try {
      await createPublicLink(presentationId, {
        requiresEmailCapture,
        pin,
        burnTimer,
        expirationDate,
        downloadsEnabled,
        commentsEnabled,
        reactionsEnabled,
      });

      toggleDisplayCreatedLink(true);
      sendAmplitudeData('SHARED_DOCUMENT_VIA_PUBLIC_LINK');
      track('link-created', { type: 'public', hasPin: false });
    } catch (err) {
      console.error(err);
      toastr.error('Error creating link. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const showCopiedMessage = () => {
    toastr.info('Link copied to clipboard', { timeOut: 1000 });
  };

  const mockUserFeatures = {
    allow_downloads: true,
    // Other features...
  };
  

  const clickCreateAnotherLink = () => {
    toggleDisplayCreatedLink(false)
  }

  const handleDownloadToggle = (isChecked) => {
    setDownloadsEnabled(isChecked);
  };
  

  const handleSelectRecipient = (selectedRecipient) => {
    toggleDisplayCreatedLink(false)
    setSelectedRecipient(selectedRecipient.value)
  }

  const handleSelectOrganisation = (selectedOrg) => {
    toggleDisplayCreatedLink(false)
    setSelectedOrganisation(selectedOrg.value)
    setSelectedRecipient(null)
  }

  const handlePinChange = (pin) => setPin(pin)
  const handleBurnTimerChange = (timer) => setBurnTimer(timer)
  const handleExpirationDateChange = (date) => setExpirationDate(date)

  return !displayingLinkCreated
    ? (
      <Container>
        <InfoWrapper>
          <InfoText>Create a link to your document that can be shared.</InfoText>
        </InfoWrapper>
        
        <MainActionSection>
          <HideLearnMoreWrapper>
            <ProPopup requiredFeature="sharing" hideLearnMore={true}>
              <PrimaryButton 
                className="btn" 
                loading={loading ? 'loading' : undefined} 
                onClick={clickCreateButton}
                aria-label="Create and copy shareable link"
                disabled={loading}
              >
                Create & copy link
              </PrimaryButton>
            </ProPopup>
          </HideLearnMoreWrapper>
        </MainActionSection>
        
        <AdvancedSettingsToggle 
          onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
          aria-expanded={showAdvancedSettings}
          aria-controls="advanced-settings-content"
        >
          <ButtonContent>
            <IconWrapper>
              <FiSettings size={22} />
            </IconWrapper>
            <span>
              {showAdvancedSettings ? 'Hide link settings' : 'Link settings (Security, Lead Capture & More)'}
            </span>
          </ButtonContent>
          <ChevronIcon isOpen={showAdvancedSettings}>
            <FiChevronDown size={18} />
          </ChevronIcon>
        </AdvancedSettingsToggle>
        
        {showAdvancedSettings && (
          <StyledBoxOuter id="advanced-settings-content">
            <Section>
              <SectionHeader id="lead-capture-heading">
                <IconContainer>
                  <FiMail size={16} aria-hidden="true" />
                </IconContainer>
                <strong>Lead capture</strong>
                <ProBadge aria-label="Pro feature">Pro feature</ProBadge>
              </SectionHeader>
              <SectionDescription id="lead-capture-description">Collect viewer emails to grow leads automatically.</SectionDescription>
              <LabeledToggle
                title="Collect email leads"
                defaultChecked={requiresEmailCapture}
                onChange={(e) => setRequiresEmailCapture(e.target.checked)}
                aria-labelledby="lead-capture-heading"
                aria-describedby="lead-capture-description"
              />
            </Section>

            <SectionDivider />
            
            <Section>
              <SectionHeader id="engagement-heading">
                <strong>Engagement</strong>
              </SectionHeader>
              <SectionDescription id="engagement-description">Encourage engagement and capture feedback.</SectionDescription>
              <EngagementToggles>
                <ToggleItem>
                  <ToggleSwitch 
                    type="button"
                    role="switch"
                    aria-checked={reactionsEnabled}
                    data-state={reactionsEnabled ? "checked" : "unchecked"}
                    onClick={() => setReactionsEnabled(!reactionsEnabled)}
                  >
                    <ToggleThumb data-state={reactionsEnabled ? "checked" : "unchecked"} />
                  </ToggleSwitch>
                  <ToggleLabel>Reactions</ToggleLabel>
                </ToggleItem>
                
                <ToggleItem>
                  <ToggleSwitch 
                    type="button"
                    role="switch"
                    aria-checked={commentsEnabled}
                    data-state={commentsEnabled ? "checked" : "unchecked"}
                    onClick={() => setCommentsEnabled(!commentsEnabled)}
                  >
                    <ToggleThumb data-state={commentsEnabled ? "checked" : "unchecked"} />
                  </ToggleSwitch>
                  <ToggleLabel>Comments</ToggleLabel>
                </ToggleItem>
                
                <ToggleItem>
                  <ToggleSwitch 
                    type="button"
                    role="switch"
                    aria-checked={downloadsEnabled}
                    data-state={downloadsEnabled ? "checked" : "unchecked"}
                    onClick={() => handleDownloadToggle(!downloadsEnabled)}
                  >
                    <ToggleThumb data-state={downloadsEnabled ? "checked" : "unchecked"} />
                  </ToggleSwitch>
                  <ToggleLabel>Downloadable</ToggleLabel>
                </ToggleItem>
              </EngagementToggles>
            </Section>

            <SectionDivider />
            
            <Section>
              <SectionHeader id="security-heading">
                <strong>Security</strong>
              </SectionHeader>
              <SectionDescription id="security-description">Control access with timers and restrictions.</SectionDescription>
              <SecurityInputContainer className="security-input">
                <PinInput
                  defaultValue={pin}
                  handleAccessPinChange={handlePinChange}
                  activeLinkPin={false}
                />
              </SecurityInputContainer>
              <ExpirationDateInput
                defaultValue={expirationDate}
                handleExpirationDateChange={handleExpirationDateChange}
              />
              <BurnTimerInput
                defaultValue={burnTimer}
                handleBurnTimerChange={handleBurnTimerChange}
              />
            </Section>
          </StyledBoxOuter>
        )}
      </Container>
    )
    : (
      <SuccessContainer>
        <SuccessHeader>
          <h3>Your document is ready to share!</h3>
          <p>Copy the link below and share it with anyone you want to view your document.</p>
        </SuccessHeader>
        
        <TrustIndicator>
          <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 4L6.00008 11.3333L2.66675 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Secure, trackable sharing enabled
        </TrustIndicator>
        
        <LinkField>
          <svg 
            className="padlock-icon" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
          </svg>
          <LinkText>{url}</LinkText>
          <CopyIconButton 
            onClick={() => {
              navigator.clipboard.writeText(url);
              showCopiedMessage();
            }}
            aria-label="Copy to clipboard"
          >
            <FiClipboard size={16} color="var(--text-color)" />
          </CopyIconButton>
        </LinkField>
        
        <ButtonContainer>
          <CopyToClipboard text={url} onCopy={() => showCopiedMessage()}>
            <CopyButton aria-label="Copy link to clipboard">
              <ClipboardIcon />
              <span>Copy link</span>
            </CopyButton>
          </CopyToClipboard>

          <AnalyticsButton 
            onClick={() => {
              closeModal();
              navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics?filter=public&link_id=${linkId}`);
            }}
            aria-label="View analytics for this document"
          >
            <AnalyticsIcon />
            <span>View Analytics</span>
          </AnalyticsButton>
        </ButtonContainer>
      </SuccessContainer>
    )
}

const Container = styled.div`
  padding: 14px 24px 24px;
  background: color-mix(in srgb, var(--background-color) 95%, white);
`

const InfoWrapper = styled.div`
  margin-bottom: 24px;
  padding-top: 4px;
`;

const InfoText = styled.p`
  font-size: 15px;
  color: var(--text-secondary-color);
  margin: 0;
  line-height: 1.5;
  max-width: 90%;
`;

const Section = styled.div`
  margin-bottom: 16px;
  padding: 0 20px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 16px;
  }

  &:first-of-type {
    padding-top: 16px;
  }

  input {
    background-color: var(--input-background-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    
    &:hover {
      background-color: var(--input-background-hover-color);
      border-color: var(--border-color-dark);
    }
    
    &:focus {
      background-color: var(--input-background-hover-color);
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-faint);
    }
  }
`;

const SectionHeader = styled.h4`
  margin: 0 0 6px;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionDescription = styled.p`
  margin: 0 0 12px;
  font-size: 14px;
  color: var(--text-secondary-color);
  line-height: 1.4;
`;

const SecurityInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
  max-width: 100%;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
  
  /* Ensure PIN input aligns horizontally */
  .expired-datepicker, .burn-input-timer, .security-input {
    flex-direction: row;
    align-items: center;
  }
  
  .security-input > div {
    display: flex;
    align-items: center;
  }
`;

const EngagementToggles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const ToggleItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ToggleSwitch = styled.button`
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
  border: 1px solid var(--border-color);
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
  
  &[data-state="checked"] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &[data-state="unchecked"] {
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }
`;

const ToggleThumb = styled.span`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &[data-state="checked"] {
    transform: translateX(20px);
  }
`;

const ToggleLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
`;

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: color-mix(in srgb, var(--primary-color) 15%, transparent);
  border-radius: 8px;
  padding: 0;
  width: 40px;
  height: 40px;
  color: var(--primary-color);
  margin-right: 8px;
  position: relative;
  
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color-mix(in srgb, var(--primary-color) 12%, transparent);
  border-radius: 8px;
  padding: 0;
  min-width: 38px;
  height: 38px;
  color: var(--primary-color);
  flex-shrink: 0;
  position: relative;
  
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ChevronIcon = styled.span`
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
  display: flex;
  align-items: center;
  opacity: 0.7;
  flex-shrink: 0;
  margin-left: 12px;
`;

const AdvancedSettingsToggle = styled.button`
  background: color-mix(in srgb, var(--primary-color) 8%, var(--background-color));
  border: 1px solid color-mix(in srgb, var(--primary-color) 15%, var(--border-color));
  color: var(--text-color);
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 6px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s ease;
  
  &:hover {
    background: color-mix(in srgb, var(--primary-color) 12%, var(--background-color));
    transform: translateY(-1px);
  }
  
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;

const StyledBoxOuter = styled.div`
  display: block;
  position: relative;
  width: 100%;
  color: var(--text-color);
  background-color: color-mix(in srgb, var(--background-color) 96%, var(--text-color) 4%);
  border-radius: 12px;
  overflow: hidden;
  margin-top: 12px;
  border: 1px solid var(--border-color);

  input, select, button:not([role="switch"]) {
    background-color: color-mix(in srgb, var(--background-color) 95%, var(--text-color) 5%);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    color: var(--text-color);
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    
    &:hover:not(:disabled) {
      background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
      border-color: var(--border-color-dark);
    }
    
    &:focus {
      background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
      border-color: var(--primary-color);
      box-shadow: 0 0 0 2px var(--primary-color-faint);
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  button[role="switch"] {
    position: relative;
    width: 44px;
    height: 24px;
    padding: 0;
    border-radius: 12px;
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;

    &[data-state="checked"] {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &[data-state="unchecked"] {
      background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &[data-state="checked"]::before {
      transform: translateX(20px);
    }
  }
`;

const MainActionSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const SectionDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: var(--border-color);
  margin: 10px 20px;
  opacity: 0.6;
`;

const SuccessContainer = styled.div`
  padding: 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SuccessHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 28px;

  h3 {
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 12px;
    color: var(--text-color);
    line-height: 1.2;
  }
  
  p {
    margin: 0;
    color: var(--text-secondary-color);
    font-size: 15px;
    line-height: 1.5;
    max-width: 85%;
  }
`;

const LinkField = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--input-background-color);
  border-radius: 8px;
  padding: 0 12px;
  height: 40px;
  margin: 0 auto 28px;
  max-width: 85%;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
  position: relative;

  .padlock-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: var(--text-secondary-color);
  }

  &:hover {
    border-color: var(--border-color-dark);
    background-color: var(--input-background-hover-color);
  }
`;

const LinkText = styled.div`
  flex: 1;
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  font-size: 13px;
  color: var(--text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
  user-select: all;
`;

const TrustIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin: 8px 0 16px;
  font-size: 13px;
  color: var(--text-secondary-color);
  
  svg {
    color: var(--success-color);
  }
`;

const CopyIconButton = styled.button`
  background: none;
  border: none;
  color: var(--text-secondary-color);
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;
  
  &:hover {
    color: var(--text-color);
    background-color: var(--background-hover-color);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  padding: 0 24px;
  height: 44px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  flex: 1;

  span {
    margin-left: 4px;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint);
  }

  &:active:not(:disabled) {
    transform: scale(0.98);
  }
`;

const CopyButton = styled(BaseButton)`
  background: var(--primary-color);
  color: white;
  border: none;
  box-shadow: 0 2px 4px var(--primary-shadow-color);

  &:hover:not(:disabled) {
    background: var(--primary-color-shade);
    box-shadow: 0 4px 8px var(--primary-shadow-color);
    transform: translateY(-1px);
  }
`;

const AnalyticsButton = styled(BaseButton)`
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);

  &:hover:not(:disabled) {
    background: var(--background-hover-color);
    border-color: var(--border-color-dark);
    transform: translateY(-1px);
  }
`;

const ClipboardIcon = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: currentColor;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'%3E%3C/path%3E%3Crect x='8' y='2' width='8' height='4' rx='1' ry='1'%3E%3C/rect%3E%3C/svg%3E") no-repeat center;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'%3E%3C/path%3E%3Crect x='8' y='2' width='8' height='4' rx='1' ry='1'%3E%3C/rect%3E%3C/svg%3E") no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
`;

const AnalyticsIcon = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--text-color);
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 20V10'%3E%3C/path%3E%3Cpath d='M18 20V4'%3E%3C/path%3E%3Cpath d='M6 20v-4'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 20V10'%3E%3C/path%3E%3Cpath d='M18 20V4'%3E%3C/path%3E%3Cpath d='M6 20v-4'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
`;

const HideLearnMoreWrapper = styled.div`
  .propopup {
    button.anchor {
      display: none !important;
    }
  }
`;

const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-color-shade));
  border-radius: 10px;
  padding: 0 28px;
  height: 48px;
  cursor: pointer;
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 240px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px var(--primary-shadow-color);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: left 0.5s ease;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px var(--primary-shadow-color);
    
    &::before {
      left: 100%;
      transition: left 0.8s ease;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary-color-faint), 0 4px 12px var(--primary-shadow-color);
  }

  &:active:not(:disabled) {
    transform: scale(0.98);
  }
`;

const ProBadge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  border-radius: 999px;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  letter-spacing: 0.3px;
`;

const LabeledToggleStyles = `
  .toggle-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .toggle-track {
    position: relative;
    width: 44px;
    height: 24px;
    margin-right: 12px;
    padding: 0;
    border-radius: 12px;
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;
  }

  .toggle-track[data-state="checked"] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .toggle-track[data-state="unchecked"] {
    background-color: color-mix(in srgb, var(--background-color) 90%, var(--text-color) 10%);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .toggle-thumb {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .toggle-thumb[data-state="checked"] {
    transform: translateX(20px);
  }

  .toggle-label {
    font-size: 14px;
    color: var(--text-color);
  }
`;

const AllowDownloadToggle = styled(LabeledToggle)`
  ${LabeledToggleStyles}
`;

export default CreatePublicLinkTab
