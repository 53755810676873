import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import TreeMenu from 'react-simple-tree-menu';
import { home, sizleCompanies, settings, team, plusCircle, creditCard, lightMode, darkMode } from '@sizle-icons';
import ListItem from './TreeMenu';
import { workspaceContext, useUserContext, ModeContext } from 'src/context';
import { useTheme } from 'src/context/ThemeContext';
import { sendAmplitudeData } from 'src/utils/amplitude';
import WorkspaceDropdown from './WorkspaceDropdown';
import { useResponsive } from 'react-hooks-responsive';
import SidebarNavLink from './SidebarNavLink';
import Footer from './Footer';
import FooterCreateIcon from './FooterCreateIcon';
import { FiChevronRight, FiChevronLeft, FiMenu, FiMoon, FiSun, FiFolder, FiRefreshCw } from 'react-icons/fi';
import StorageLimit from 'src/modules/navigation/main-sidebar/components/StorageLimit';
import '../styles.css';
import { reloadWorkspaceData } from 'src/api/interceptors';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openTreeNodes, setOpenTreeNodes] = useState([]);
  const [focusKey, setFocusNodeKey] = useState(null);
  const [applyingCreateLoader, setApplyingCreateLoader] = useState(false);
  const [folderState, setFolderState] = useState('initializing'); // 'initializing', 'loading', 'ready', 'error'
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [workspaceDisplayState, setWorkspaceDisplayState] = useState('initializing'); // 'initializing', 'loading', 'ready', 'error'
  const { setActiveFolder, activeFolder, setNotificationView, notificationView } = useUserContext();
  const { workspaceIndex, hasWorkspacePermission, folders, addFolder, workspacesLoading, refetchWorkspaces, workspace: currentWorkspace, workspaces } = useContext(workspaceContext);
  const { isDarkMode, toggleTheme } = useTheme();
  const { mode, setMode } = useContext(ModeContext);
  const { t } = useTranslation();
  const breakpoints = { mobile: 0, desktop: 855 };
  const { screenIsAtMost, screenIsAtLeast } = useResponsive(breakpoints);
  const isMobile = screenIsAtLeast('mobile') && screenIsAtMost('desktop');
  const [compactNav, setCompactNav] = useState(isMobile);
  const [width, setWidth] = useState(window.innerWidth);

  // Track rate limit detection
  useEffect(() => {
    const handleRateLimit = (e) => {
      if (e.detail && e.detail.status === 429) {
        setIsRateLimited(true);
        // Auto-retry after 10 seconds for rate limits
        setTimeout(() => {
          handleRetryLoad();
        }, 10000);
      }
    };

    window.addEventListener('rate-limit-detected', handleRateLimit);
    return () => window.removeEventListener('rate-limit-detected', handleRateLimit);
  }, []);

  // Monitor workspaces and folders loading state
  useEffect(() => {
    // Update workspace state
    if (workspacesLoading) {
      setWorkspaceDisplayState('loading');
    } else if (!workspaces || workspaces.length === 0) {
      setWorkspaceDisplayState(retryAttempt >= 3 ? 'error' : 'initializing');
    } else {
      setWorkspaceDisplayState('ready');
    }
    
    // Update folder state
    if (workspacesLoading) {
      setFolderState('loading');
    } else if (folders === undefined || folders === null) {
      setFolderState('initializing');
    } else {
      setFolderState('ready');
      setIsRateLimited(false); // Clear rate limit flag on successful load
    }
  }, [folders, workspaces, workspacesLoading, retryAttempt]);

  // Auto retry loading workspaces if none available and not loading
  useEffect(() => {
    let retryTimeout;
    if (!workspacesLoading && 
        (!workspaces || workspaces.length === 0 || !currentWorkspace) && 
        retryAttempt < 3) {
      retryTimeout = setTimeout(() => {
        console.log(`Auto-retrying workspace load (attempt ${retryAttempt + 1}/3)...`);
        setRetryAttempt(prev => prev + 1);
        refetchWorkspaces && refetchWorkspaces();
      }, 3000 * (retryAttempt + 1)); // Progressive delay
    }
    return () => clearTimeout(retryTimeout);
  }, [workspacesLoading, workspaces, currentWorkspace, retryAttempt, refetchWorkspaces]);

  // Function to handle theme toggle that updates both contexts
  const handleThemeToggle = () => {
    console.log('Theme toggle clicked. Current state:', { isDarkMode, mode });
    
    // First toggle the ThemeContext
    toggleTheme();
    
    // Then explicitly set the ModeContext value
    const newMode = isDarkMode ? 'light' : 'dark';
    setMode(newMode);
    
    console.log('Theme toggle completed. New state:', { isDarkMode: !isDarkMode, mode: newMode });
    
    // Log the change for analytics
    sendAmplitudeData('THEME_CHANGED', { theme: newMode });
  };

  // Handler for manual retry
  const handleRetryLoad = () => {
    setFolderState('loading');
    setWorkspaceDisplayState('loading');
    setIsRateLimited(false);
    setRetryAttempt(0);
    
    // First try to refetch if available
    if (refetchWorkspaces) {
      refetchWorkspaces();
    } else {
      // Otherwise use the helper from interceptors
      reloadWorkspaceData();
    }
    
    // Show notification to user
    toastr.info('Reloading data', 'Please wait while we reload your workspace data', { timeOut: 3000 });
  };

  useEffect(() => {
    // Set initial theme
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, []);

  // Improved resize handler with debounce
  useEffect(() => {
    let timeoutId = null;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
        
        if (newWidth <= 855) {
          if (notificationView) setNotificationView(false);
          if (!compactNav) {
            const elm = document.getElementById('mainContentContainer');
            if (elm?.classList) elm.classList.add('compacted-nav');
            setCompactNav(true);
          }
        } else if (newWidth > 855 && compactNav) {
          const elm = document.getElementById('mainContentContainer');
          if (elm?.classList) elm.classList.remove('compacted-nav');
          setCompactNav(false);
        }
      }, 150);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [width, notificationView, compactNav]);

  // Ensure correct initial state
  useEffect(() => {
    const elm = document.getElementById('mainContentContainer');
    if (compactNav && elm && !elm.classList.contains('compacted-nav')) {
      elm.classList.add('compacted-nav');
    }
  }, [compactNav]);

  const toggleNav = (forceAction) => {
    const elm = document.getElementById('mainContentContainer');
    
    if (forceAction === 'close') {
      setCompactNav(true);
      if (elm?.classList) {
        elm.classList.add('compacted-nav');
      }
      return;
    }
    
    if (forceAction === 'open') {
      setCompactNav(false);
      if (elm?.classList) {
        elm.classList.remove('compacted-nav');
      }
      return;
    }

    // Toggle behavior
    const newCompactState = !compactNav;
    setCompactNav(newCompactState);

    if (elm?.classList) {
      if (newCompactState) {
        elm.classList.add('compacted-nav');
      } else {
        elm.classList.remove('compacted-nav');
      }
    }

    if (isMobile) {
      const sidebar = document.querySelector('.sidebar');
      if (sidebar) {
        if (newCompactState) {
          sidebar.classList.remove('sidebar-expanded');
        } else {
          sidebar.classList.add('sidebar-expanded');
        }
      }
    }
  };

  // Improved folder management - completely rewritten with better error handling
  useEffect(() => {
    try {
      // Skip processing if we don't have valid folders data yet
      if (!folders || !Array.isArray(folders)) {
        return;
      }
      
      // If we have folders, process them
      if (folders.length > 0) {
        const findFolder = (folder, key) => {
          if (!folder || !key) return null;
          
          try {
            if (parseInt(folder.folderId) === parseInt(key)) return `${key}`;
            
            // Ensure folder.nodes exists and is an array before mapping
            if (!folder.nodes || !Array.isArray(folder.nodes)) return null;
            
            const found = folder.nodes
              .filter(f => f) // Filter out any null/undefined nodes
              .map(f => findFolder(f, key))
              .find(a => a);
              
            return found ? `${folder.folderId}/${found}` : null;
          } catch (err) {
            console.error('Error in findFolder:', err);
            return null;
          }
        };

        // Skip this if folders aren't properly shaped
        if (folders.some(f => !f || !f.folderId)) {
          console.warn('Some folders are missing folderId property');
          return;
        }

        const newFocusKey = folders
          .filter(f => f) // Ensure we only process valid folder objects
          .map(f => findFolder(f, activeFolder))
          .filter(a => a) // Filter out nulls
          .find(a => a);
          
        if (newFocusKey) {
          try {
            const openedFolder = newFocusKey.split('/').slice(0, -1).join('/');
            setOpenTreeNodes(prev => [...new Set([...prev, openedFolder])]);
            setFocusNodeKey(`${newFocusKey}`);
          } catch (err) {
            console.error('Error setting focus key:', err);
          }
        } else if (folders[0]?.folderId) {
          setActiveFolder(folders[0].folderId);
        }
      } else if (folders.length === 0) {
        // Handle empty folders case - set a dummy root key
        setFocusNodeKey('empty_root');
      }
    } catch (err) {
      console.error('Error in folder management useEffect:', err);
      // Set an error state that our UI can handle
      setFolderState('error');
    }
  }, [activeFolder, folders, setActiveFolder]);

  const onClickFolder = ({ key }) => {
    try {
      if (pathname.indexOf('/presentations') === -1) {
        navigate(`/s/${workspaceIndex}/presentations`);
      }

      const activatedFolderId = key.split('/').pop();
      if (key !== focusKey) {
        setActiveFolder(activatedFolderId);
      } else {
        setOpenTreeNodes(prev => {
          const isOpen = prev.includes(key);
          return isOpen ? prev.filter(node => node !== key) : [...prev, key];
        });
      }

      if (isMobile) toggleNav();
    } catch (err) {
      console.error('Error in onClickFolder:', err);
      toastr.error(t('Error navigating to folder'));
    }
  };

  const onClickCreateFolder = async (parentId = null) => {
    if (!currentWorkspace?.workspace_id) {
      toastr.warning('Workspace not available', 'Please wait for workspace to load or try refreshing the page');
      return;
    }
    
    setApplyingCreateLoader(true);
    try {
      const newFolderName = t('New Folder');
      await addFolder(newFolderName, parentId);
      toastr.success(t('Folder created'), t('Your new folder has been created'));
    } catch (error) {
      console.error('Error creating folder:', error);
      
      if (error.message && error.message.includes('rate limit')) {
        setIsRateLimited(true);
        toastr.warning(
          t('Service is busy'), 
          t('Please try again in a few moments')
        );
      } else {
        toastr.error(
          t('Could not create folder'), 
          error.message || t('Please try again later')
        );
      }
    } finally {
      setApplyingCreateLoader(false);
    }
  };

  const handleSignOut = () => {
    // Add your sign out logic here
    navigate('/auth/logout');
  };

  const toggleButton = (
    <button 
      className="collapse-button"
      onClick={() => toggleNav()}
      aria-label={compactNav ? t('Expand sidebar') : t('Collapse sidebar')}
    >
      {isMobile ? <FiMenu /> : (compactNav ? <FiChevronRight /> : <FiChevronLeft />)}
    </button>
  );

  // Improved item count function with better error handling
  const getFolderItemCount = (folderId) => {
    try {
      if (!folders || !Array.isArray(folders) || !folderId) return 0;
      
      const folder = folders.find(f => f && f.folderId === folderId);
      if (!folder) return 0;
      
      // Count direct items in the folder
      const directItems = folder.presentations?.length || 0;
      
      // Check if nodes exists and is an array
      if (!folder.nodes || !Array.isArray(folder.nodes)) return directItems;
      
      // Recursively count items in subfolders
      const subfolderItems = folder.nodes.reduce((acc, subfolder) => {
        if (!subfolder || !subfolder.folderId) return acc;
        return acc + getFolderItemCount(subfolder.folderId);
      }, 0);
      
      return directItems + subfolderItems;
    } catch (err) {
      console.error('Error calculating folder item count:', err);
      return 0;
    }
  };

  // Render folder content based on state
  const renderFolderContent = () => {
    // If workspaces aren't loaded or available yet, show appropriate message
    if (!currentWorkspace?.workspace_id && workspaceDisplayState !== 'ready') {
      return (
        <div className="empty-folder-state">
          <FiFolder className="folder-icon" size={24} />
          <p>{t('No folders yet')}</p>
          <div className="workspace-unavailable">
            <p className="error-note">{t('Workspace not available')}</p>
            <button 
              className="retry-button small"
              onClick={handleRetryLoad}
            >
              <FiRefreshCw size={14} className="icon-left" />
              {t('Refresh')}
            </button>
          </div>
          {workspacesLoading && (
            <p className="loading-note">{t('Preparing workspace...')}</p>
          )}
        </div>
      );
    }
    
    if (folderState === 'loading' || workspacesLoading) {
      return (
        <div className="folder-loading-state">
          <div className="loading-indicator"></div>
          <p>{t('Loading folders...')}</p>
        </div>
      );
    }
    
    if (isRateLimited) {
      return (
        <div className="folder-error-state rate-limited">
          <FiRefreshCw className="retry-icon pulse" size={24} />
          <p>{t('Service is busy')}</p>
          <p className="error-subtitle">{t('We\'ll automatically retry loading your data')}</p>
          <button 
            className="retry-button"
            onClick={handleRetryLoad}
          >
            {t('Retry Now')}
          </button>
        </div>
      );
    }
    
    if (folderState === 'error') {
      return (
        <div className="folder-error-state">
          <p>{t('Error loading folders')}</p>
          <button 
            className="retry-button"
            onClick={handleRetryLoad}
          >
            {t('Retry')}
          </button>
        </div>
      );
    }
    
    // Empty or no folders case
    if (!folders || folders.length === 0) {
      return (
        <div className="empty-folder-state">
          <FiFolder className="folder-icon" size={24} />
          <p>{t('No folders yet')}</p>
          <button 
            className="create-folder-button" 
            onClick={() => onClickCreateFolder(null)}
            disabled={applyingCreateLoader || !currentWorkspace?.workspace_id}
          >
            {applyingCreateLoader ? 
              <span className="loader-text">
                <span className="loading-indicator small"></span>
                {t('Creating...')} 
              </span>
              : t('Create first folder')
            }
          </button>
          {workspacesLoading && (
            <p className="loading-note">{t('Preparing workspace...')}</p>
          )}
          {!currentWorkspace?.workspace_id && !workspacesLoading && (
            <div className="workspace-unavailable">
              <p className="error-note">{t('Workspace not available')}</p>
              <button 
                className="retry-button small"
                onClick={handleRetryLoad}
              >
                <FiRefreshCw size={14} className="icon-left" />
                {t('Refresh')}
              </button>
            </div>
          )}
          {folderState === 'error' && (
            <p className="error-note">{t('There was an error. Please try again.')}</p>
          )}
        </div>
      );
    }
    
    // We have folders and focus key, render the tree
    if (focusKey) {
      return (
        <TreeMenu
          data={folders}
          onClickItem={onClickFolder}
          openNodes={openTreeNodes}
          initialFocusKey={focusKey}
          focusKey={focusKey}
          debounceTime={125}
          resetOpenNodesOnDataUpdate={false}
        >
          {({ items }) => (
            <div>
              {Array.isArray(items) && items.length > 0 ? (
                items.map(({ key, ...props }) => (
                  <ListItem
                    key={key}
                    onCreateNewFolder={onClickCreateFolder}
                    folderId={props.folderId}
                    isOriginal={props.is_original}
                    itemCount={getFolderItemCount(props.folderId)}
                    {...props}
                  />
                ))
              ) : (
                <div className="no-tree-items">
                  <p>{t('No folder structure available')}</p>
                </div>
              )}
            </div>
          )}
        </TreeMenu>
      );
    }
    
    // Fallback case - should not reach here normally
    return (
      <div className="empty-folder-state">
        <p>{t('Ready to create folders')}</p>
      </div>
    );
  };

  return (
    <>
      <div 
        className={`sidebar ${compactNav ? 'compacted' : ''} ${isMobile && !compactNav ? 'sidebar-expanded' : ''}`}
        role="navigation"
        aria-label={t('Main navigation')}
      >
        <div className="sidebar-inner">
          <div className="sidebar-content">
            <WorkspaceDropdown toggleNav={toggleNav} />
            
            <h2 className="tree-menu-header">
              {t('Folders')}
              <span className="folder-count">{folders && Array.isArray(folders) ? folders.length : 0}</span>
            </h2>
            
            <div className="tree-menu-container">
              {renderFolderContent()}
              
              {folderState === 'ready' && hasWorkspacePermission && hasWorkspacePermission('Manage Folders') && !(!folders || folders.length === 0) && (
                <button 
                  className="footer-link" 
                  onClick={() => onClickCreateFolder()}
                  disabled={applyingCreateLoader}
                  aria-label={t('Create new folder')}
                >
                  <FooterCreateIcon />
                  <span>{applyingCreateLoader ? t('Creating...') : t('Create folder')}</span>
                </button>
              )}
            </div>
            
            <div className="sidebar-links">
              <SidebarNavLink 
                mini={compactNav} 
                to={`/s/${workspaceIndex}/settings`} 
                icon={settings}
                aria-label={t('Workspace settings')}
              >
                {t('Workspace')}
              </SidebarNavLink>
              <SidebarNavLink 
                mini={compactNav} 
                to={`/s/${workspaceIndex}/billing#monthly`} 
                icon={creditCard} 
                iconName="credit-card"
                aria-label={t('Billing settings')}
              >
                {t('Billing')}
              </SidebarNavLink>
              <SidebarNavLink 
                mini={compactNav} 
                isActionableAnchor={true}
                onClickItem={handleThemeToggle}
                icon={isDarkMode ? lightMode : darkMode}
                iconName="theme"
                aria-label={isDarkMode ? t('Switch to light mode') : t('Switch to dark mode')}
              >
                {t('Theme')}
              </SidebarNavLink>
            </div>
          </div>

          <Footer compactNav={compactNav} />

          <div className="sidebar-footer">
            <StorageLimit className="storage-limit" />
            {toggleButton}
          </div>
        </div>
      </div>

      {isMobile && (
        <button 
          className="mobile-toggle-nav-btn" 
          onClick={() => toggleNav()}
          aria-label={compactNav ? t('Expand sidebar') : t('Collapse sidebar')}
        >
          <FiMenu />
        </button>
      )}
    </>
  );
};

Sidebar.propTypes = {};

export default Sidebar;

