import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'src/modules/modals';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useAnalytics } from 'use-analytics';
import { toastr } from 'react-redux-toastr';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { useTranslation } from 'react-i18next';
import { workspaceContext } from 'src/context';

const StyledModalContent = styled(ModalContent)`
  max-width: 480px;
  width: 100%;
  background: var(--modal-bg, #fff);
  border: none;
  box-shadow: var(--modal-shadow);
  border-radius: 16px;
  padding: 0;
  overflow: hidden;

  .modal-header {
    padding: 40px 32px 32px;
    text-align: center;
    border-bottom: 1px solid var(--border-subtle, #E5E7EB);

    h2 {
      font-size: 24px;
      font-weight: 600;
      color: var(--text-primary, #111827);
      margin-bottom: 8px;
      line-height: 1.2;
    }

    p {
      font-size: 15px;
      color: var(--text-secondary, #6B7280);
      margin: 0;
      line-height: 1.5;
    }
  }

  .modal-body {
    padding: 32px;

    .form-group {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: block;
        font-size: 15px;
        font-weight: 500;
        color: var(--text-primary, #111827);
        margin-bottom: 12px;
      }

      .helper-text {
        font-size: 13px;
        color: var(--text-secondary, #6B7280);
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        &::before {
          content: '⌘';
          font-size: 13px;
          color: var(--text-secondary, #6B7280);
        }
      }

      .input-text {
        width: 100%;
        height: 44px;
        background: var(--input-bg, #fff);
        border: 1px solid var(--border-subtle, #E5E7EB);
        border-radius: 8px;
        padding: 0 16px;
        font-size: 15px;
        color: var(--text-primary, #111827);
        transition: all 0.15s ease;

        &:hover {
          border-color: var(--border-hover, #D1D5DB);
        }

        &:focus {
          border-color: var(--primary-color, #6366F1);
          outline: none;
          box-shadow: 0 0 0 3px var(--primary-shadow, rgba(99, 102, 241, 0.12));
        }

        &::placeholder {
          color: var(--text-placeholder, #9CA3AF);
        }
      }
    }

    .avatar-upload {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .avatar-preview {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        background: var(--avatar-bg, #F3F4F6);
        border: 1px solid var(--border-subtle, #E5E7EB);
        overflow: hidden;
        position: relative;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:empty::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 24px;
          height: 24px;
          background: var(--icon-color, #9CA3AF);
          opacity: 0.4;
          mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z'/%3E%3C/svg%3E");
          mask-repeat: no-repeat;
          mask-position: center;
        }
      }

      .upload-controls {
        flex: 1;

        .file-input-row {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 8px;
        }

        .choose-file-btn {
          background: var(--button-secondary-bg, #fff);
          border: 1px solid var(--border-subtle, #E5E7EB);
          border-radius: 6px;
          color: var(--text-primary, #374151);
          font-size: 14px;
          font-weight: 500;
          padding: 6px 12px;
          cursor: pointer;
          transition: all 0.15s ease;

          &:hover {
            background: var(--button-secondary-hover, #F9FAFB);
            border-color: var(--border-hover, #D1D5DB);
          }
        }

        .file-name {
          color: var(--text-secondary, #6B7280);
          font-size: 14px;
        }

        .upload-btn {
          width: 100%;
          height: 36px;
          background: var(--button-secondary-bg, #fff);
          border: 1px solid var(--border-subtle, #E5E7EB);
          border-radius: 6px;
          color: var(--text-primary, #374151);
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          cursor: pointer;
          transition: all 0.15s ease;

          &:hover {
            background: var(--button-secondary-hover, #F9FAFB);
            border-color: var(--border-hover, #D1D5DB);
          }
        }
      }

      input[type='file'] {
        display: none;
      }
    }
  }

  .modal-footer {
    padding: 24px 32px;
    border-top: 1px solid var(--border-subtle, #E5E7EB);
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .btn {
      height: 40px;
      padding: 0 20px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.15s ease;

      &.primary {
        background: var(--primary-color, #6366F1);
        color: #fff;
        border: none;
        min-width: 140px;

        &:hover:not(:disabled) {
          background: var(--primary-color-darker, #4F46E5);
        }

        &:focus-visible {
          outline: none;
          box-shadow: 0 0 0 3px var(--primary-shadow, rgba(99, 102, 241, 0.12));
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &.secondary {
        background: var(--button-secondary-bg, #fff);
        border: 1px solid var(--border-subtle, #E5E7EB);
        color: var(--text-primary, #374151);

        &:hover {
          background: var(--button-secondary-hover, #F9FAFB);
          border-color: var(--border-hover, #D1D5DB);
        }

        &:focus-visible {
          outline: none;
          box-shadow: 0 0 0 3px var(--primary-shadow, rgba(99, 102, 241, 0.12));
        }
      }
    }
  }

  /* Dark mode styles */
  [data-theme="dark"] & {
    --modal-bg: #1E1E1E;
    --text-primary: #F9FAFB;
    --text-secondary: #9CA3AF;
    --text-placeholder: #6B7280;
    --border-subtle: rgba(255, 255, 255, 0.1);
    --border-hover: rgba(255, 255, 255, 0.2);
    --input-bg: #2A2A2A;
    --button-secondary-bg: #2A2A2A;
    --button-secondary-hover: #323232;
    --avatar-bg: #2A2A2A;
    --icon-color: #6B7280;
    --primary-color: #6366F1;
    --primary-color-darker: #4F46E5;
    --primary-shadow: rgba(99, 102, 241, 0.2);
  }
`;

function CreateWorkspaceModal({ isOpen, dismissModal }) {
  const [avatarDataURI, setAvatarDataURI] = useState(null);
  const { workspaces, createWorkspace } = useContext(workspaceContext);
  const [loading, setLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const fileInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { setModal } = useModal();
  const navigate = useNavigate();

  // Focus name input when modal opens
  useEffect(() => {
    if (isOpen) {
      // Small delay to ensure modal is fully rendered
      const timer = setTimeout(() => {
        nameInputRef.current?.focus();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleNameChange = (e) => {
    setWorkspaceName(e.target.value);
  };

  const handleKeyDown = (e) => {
    // Submit on Cmd/Ctrl + Enter
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      if (form && !form.querySelector('button[type="submit"]').disabled) {
        form.requestSubmit();
      }
    }
  };

  const submitNewWorkspace = async (event) => {
    event.preventDefault();

    const trimmedName = workspaceName.trim();
    if (!trimmedName) {
      toastr.error(t('Workspace name is required.'));
      return;
    }

    setLoading(true);
    try {
      await createWorkspace(trimmedName, event.target.avatar.files[0]);
      
      // First update UI optimistically
      setModal(null);
      navigate(`/s/${workspaces.length}/presentations`);
      
      // Then show success message
      toastr.success(t('Your workspace has been created successfully.'));
      track('workspace-created');
      sendAmplitudeData('WORKSPACE_CREATED');
    } catch (e) {
      toastr.error(t('An error occurred while creating your workspace.'));
      track('workspace-create-error');
      setLoading(false);
    }
  };

  const updatedAvatar = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toastr.error(t('Please select an image file.'));
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toastr.error(t('Image size should be less than 5MB.'));
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => setAvatarDataURI(fileReader.result);
    fileReader.onerror = () => toastr.error(t('Failed to load image.'));
    fileReader.readAsDataURL(file);
  };

  return (
    <Modal
      size="md"
      isCentered
      closeOnOverlayClick
      closeOnEsc
      isOpen={isOpen}
      onClose={dismissModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalOverlay background="rgba(0, 0, 0, 0.4)" backdropFilter="blur(4px)" />
      <StyledModalContent role="dialog">
        <ModalCloseButton 
          top="24px" 
          right="24px" 
          color="var(--text-color-light)"
          _hover={{ 
            background: "var(--background-hover)",
            color: "var(--text-color)"
          }}
          aria-label={t('Close modal')}
        />
        
        <div className="modal-header">
          <h2 id="modal-title">{t('Create a new workspace')}</h2>
          <p id="modal-description">{t('Set up a shared workspace and invite your team.')}</p>
        </div>

        <form onSubmit={submitNewWorkspace} onKeyDown={handleKeyDown}>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="workspaceName">{t('Workspace name')}</label>
              <input
                ref={nameInputRef}
                className="input-text"
                type="text"
                name="name"
                id="workspaceName"
                value={workspaceName}
                onChange={handleNameChange}
                placeholder={t('e.g. "Marketing Team"')}
                aria-required="true"
                maxLength="50"
              />
              <div className="helper-text">
                {t('Press ⌘ + Enter to create')}
              </div>
            </div>

            <div className="form-group">
              <label id="avatar-label">{t('Workspace icon')}</label>
              <div className="avatar-upload">
                <div className="avatar-preview">
                  {avatarDataURI && (
                    <img src={avatarDataURI} alt={t('Workspace icon preview')} />
                  )}
                </div>
                <div className="upload-controls">
                  <div className="file-input-row">
                    <button
                      type="button"
                      className="choose-file-btn"
                      onClick={handleAvatarClick}
                    >
                      {t('Choose file')}
                    </button>
                    <span className="file-name">
                      {fileInputRef.current?.files?.[0]?.name || t('No file chosen')}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="upload-btn"
                    onClick={handleAvatarClick}
                  >
                    {t('Upload')}
                  </button>
                </div>
                <input
                  ref={fileInputRef}
                  onChange={updatedAvatar}
                  id="workspace-create-avatar"
                  name="avatar"
                  type="file"
                  accept="image/*"
                  aria-label={t('Upload workspace icon')}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn secondary"
              onClick={dismissModal}
            >
              {t('Cancel')}
            </button>
            <button
              type="submit"
              className="btn primary"
              disabled={loading || !workspaceName.trim()}
            >
              {loading ? t('Creating...') : t('Create workspace')}
            </button>
          </div>
        </form>
      </StyledModalContent>
    </Modal>
  );
}

CreateWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dismissModal: PropTypes.func.isRequired,
};

export default CreateWorkspaceModal;
